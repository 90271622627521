export default {
  config: {
    displayId: null,
    settingsId: null,
    setupId: null,
    loanId: null,
    apr: null,
    tilFinanceCharge: null,
    tilTotalOfPayments: null,
    tilLoanAmount: null,
    loanAmount: null,
    loanType: null,
    loanRate: null,
    paymentFrequency: null
  },

  currentStatus: {
    date: null,
    amountDue: null,
    nextPaymentDate: null,
    nextPaymentAmount: null,
    lastPaymentDate: null,
    lastPaymentAmount: null,
    principalBalance: null,
    amountPastDue30: null,
    daysPastDue: 0,
    dueInterest: null,
    duePrincipal: null,
    payoff: null,
    loanStatusId: 2,
    loanStatusText: null,
    loanSubStatusId: null,
    loanSubStatusText: null,
    availableCredit: null,
    creditLimit: null
  },

  debtCompany: {
    name: null,
    address1: null,
    address2: null,
    phone: null,
    email: null,
    website: null
  },

  autopays: [],
  payments: [],
  transactions: [],
  paymentSchedules: [],

  paymentFrequencies: [
    { title: "Weekly", value: "weekly", description: "week" },
    { title: "Bi-Weekly", value: "biWeekly", description: "every other week" },
    { title: "Monthly", value: "monthly", description: "month" }
  ],

  datePickerConfig: {
    enable: []
  },

  latestStatement: null,
};
