<template>
  <input
    type="number"
    :value="value"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    @change="$emit('input', $event.target.value)"
  >
</template>

<script>
export default {
  props: {
    mask: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
  }
};
</script>
