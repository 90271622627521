<template>
  <div class="my-select">
    <multiselect
      v-model="internalValue"
      v-bind="$attrs"
      :options="options"
      :label="label"
      :track-by="trackBy"
      :placeholder="placeholder"
      :disabled="disabled"
      :multiple="multiple"
      :show-labels="false"
      :allow-empty="false"
      :required="required"
      open-direction="below"
      v-on="$listeners"
      @input="onInput"
    />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
  name: "Select",
  components: { Multiselect },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    // The identifier of the text we want to display (title, label)
    label: {
      type: String,
      default: "id",
    },
    // The id or unique identifier we are using to track selected
    trackBy: {
      type: String,
      default: "id",
    },
    placeholder: {
      type: String,
      default: "Please select...",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.internalValue = this.options.find((option) => option[this.trackBy] === value);
      },
    },
  },
  mounted() {
    this.internalValue = this.options.find((option) => option[this.trackBy] === this.value);
  },
  methods: {
    onInput() {
      this.$emit("input", this.internalValue);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.my-select {
  .multiselect {
    position: relative !important;
    &__placeholder,
    .multiselect__input::placeholder {
      font-size: 1.4em;
      padding: 0;
      margin: 0;
      line-height: 1;
    }
    &__tags {
      border-radius: 0;
      height: 58px;
      padding: 18px 40px 0 8px;
    }
    &__input {
      border: none !important;
    }
    &__single {
      font-size: 1.6em;
    }
    &__select {
      height: 58px;
      &:before {
        background: url($select-opener) no-repeat;
        border: none;
        content: "";
        width: 25px;
        height: 25px;
        position: absolute;
        top: 40%;
        right: 50%;
        margin: -10px -10px 0 0;
      }
    }
    &__option {
      &--highlight {
        background: #e6e6e6;
        color: #000000;
      }
      &--selected {
        background: #e6e6e6 !important;
        color: #000000 !important;
      }
    }

    &__element {
      padding-bottom: 3px;
      padding-top: 3px;
      font-size: 1.5em;
    }
  }

  @media (max-width: 767px) {
    .my-select .multiselect__single {
      font-size: 1.5em;
    }

    multiselect__element {
      font-size: 1.3em;
    }
  }
}
</style>
