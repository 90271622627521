import Vue from "vue";
import Vuelidate from "vuelidate";
import VueCardFormat from "vue-credit-card-validation";
import VueGtm from "vue-gtm";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import FlashMessage from "@smartweb/vue-flash-message";
import moduleLoader from "@secure/moduleLoader";
import EnvironmentBanner from "@/plugins/environment-banner/environment-banner";
import PrivateLabelLoader from "@/config/PrivateLabelLoader";
import Filters from "@/filters";
import Inputmask from "@/helpers/inputmask";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./http";
import "@/helpers/fontawesome";

// import common css files
import "flatpickr/dist/flatpickr.css";
import "./assets/css/grid.css";
import "./assets/css/main.css";

import "./registerGlobals";

Bugsnag.start({
  apiKey: process.env.VUE_APP_SECURE_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginVue()],
  enabledReleaseStages: ["production", "staging"],
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.VUE_APP_VERSION,
  onError(event) {
    event.addMetadata("brand", { name: process.env.VUE_APP_HEAD_TITLE });
  }
});
Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);

// eslint-disable-next-line no-console
console.info(`appVersion: ${process.env.VUE_APP_VERSION}`);

Vue.directive("inputmask", Inputmask);
Vue.use(Vuelidate);
Vue.use(Filters);
Vue.use(VueCardFormat);
Vue.use(PrivateLabelLoader);
Vue.use(VueGtm, {
  enabled: !!process.env.VUE_APP_GTM_ID, // GTM scripts are only injected if the gtm_id exists
  id: process.env.VUE_APP_GTM_ID,
  debug: false, // console.logs every action
  vueRouter: router,
  ignoredViews: []
});
Vue.use(EnvironmentBanner);
Vue.use(FlashMessage);

Vue.prototype.$axios = http;

Vue.config.productionTip = false;

// eslint-disable-next-line wrap-iife
(async function() {
  await moduleLoader.registerProductModule(store, router);

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
})();
