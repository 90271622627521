import createLogger from "vuex/dist/logger";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const plugins = [];
const isNotProduction = process.env.NODE_ENV !== "production";

// add vuex logger only in production
if (isNotProduction) {
  plugins.push(createLogger({}));
}

// Vue persist pluging
const ls = new SecureLS({ isCompression: false });
plugins.push(
  createPersistedState({
    // TODO : make sure we clean remove the lead info after logout
    paths: [
      "auth.isLoggedIn",
      "customer.leadInfo",
      "customer.isSmsApproved",
      "loan.datePickerConfig",
      "productLoanType",
      "productLoanId",
      "productMenus",
      "drawRequest"
    ],
    storage: {
      getItem: key => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: key => ls.remove(key)
    }
  })
);

export default plugins;
