import state from "./loanState";
import mutations from "./loanMutations";
import getters from "./loanGetters";
import actions from "./loanActions";

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
