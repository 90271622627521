<script>
export default {
  name: "HeadTitle",

  props: {
    title: {
      type: String,
      default: process.env.VUE_APP_HEAD_TITLE
    }
  },

  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      }
    }
  },

  render() { return this; }
};
</script>
