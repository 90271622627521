import {
  AutopayStatus, AutopayTypes, PaymentTypes, TransactionTypes
} from "@secure/services/LpConstants";
import _find from "lodash/find";
import _get from "lodash/get";
import { DateTime } from "luxon-business-days";

export default {
  loanId(state) {
    return state.config.loanId;
  },

  displayId(state) {
    return state.config.displayId;
  },

  availablePaymentDates(state) {
    return state.datePickerConfig.enable;
  },

  isLoanPendingFunding(state, getters, rootState) {
    // eslint-disable-next-line camelcase
    const hasLoanInQueue = rootState.customer?.leadInfo?.has_loan_in_queue ?? false;
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 18 || (loanStatusId === 10 && loanSubStatusId === 50) || hasLoanInQueue;
  },

  isLoanInCollectionPortal(state) {
    // check if loan is in collection portal
    // LoanStatus: Collections = 17
    // Regular settlement portal loanSubStatus:
    //   'Account Frozen' = 150,
    //   'Pending Placement' = 149,
    //   'Settlement Offer 1' = 152,
    //   'Settlement Offer 2' = 151
    //    'Past Due Support' = 217
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 17 && [149, 150, 151, 152, 217].includes(loanSubStatusId);
  },

  isLoanInChargeOffNotSold(state) {
    // check if loan is in charge off
    // LoanStatus = 'Charge Off' = 20
    // Settlement Portal loanSubStatus:
    // 'Unsold' = 177
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 20 && [177].includes(loanSubStatusId);
  },

  isCovidCollection(state) {
    // LoanStatus: Collections = 17 and subStatus: 'COVID' =178
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 17 && loanSubStatusId === 178;
  },

  isLoanPaidOff(state) {
    // option 1: 'Paid Off' = 3 and 'Early Payoff' = 17
    // option 2: 'Paid Off' = 3 and 'Full Term' = 16
    // option 2: 'Paid Off' = 3 and 'Settled Full Balance' = 175
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 3 && [16, 17, 175, 211, 212].includes(loanSubStatusId);
  },

  hasNewInstallmentOffer(state) {
    // option 1: 'Paid Off' = 3 and 'Offer Redeemed' = 211
    // option 2: 'Paid Off' = 3 and 'Offer Originated' = 212
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 3 && [211, 212].includes(loanSubStatusId);
  },

  isEligibleForInstallmentOffer(state, _, rootState) {
    if (!_get(rootState, "customer.settings.product_discontinued", false)) {
      return true;
    }

    // loanStatus: 'Open' = 2
    // loanSubStatus: 'Current Loan' = 9
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 2 && loanSubStatusId === 9;
  },

  isLoanPendingFinalPayment(state) {
    // option 1: Paid Off = 3 and Final Payment Pending = 139
    // option 2: Settlement = 19 and Final Payment Pending = 144
    // option 2: Settlement = 19 and Portal 3 - Final Payment Pending = 179
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return [3, 19].includes(loanStatusId) && [139, 144, 179].includes(loanSubStatusId);
  },

  isLoanSoldOff(state) {
    // loanStatus: 'Charge Off' = 20
    //    loanSubStatus: 'Sold - NCA' = 164
    //    loanSubStatus: 'Sold - Midwest' = 216
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 20 && [164, 186, 187, 216, 218].includes(loanSubStatusId);
  },

  isLoanPendingRescinded(state) {
    // Loan Status ID: 2, Loan Status Sub ID: 182
    return state.loanStatusId === 2 && state.loanSubStatusId === 182;
  },

  isLoanRescinded(state, getters) {
    // loanStatus: 'Loan Documents' = 10
    // loanSubStatus:
    // 'Loan Docs Signed-Rescinded' = 55
    // 'Rescinded - Active Offer' = 180
    // 'Rescinded - Offer Expired' = 181
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return getters.isLoanPendingRescinded || (loanStatusId === 10 && [54, 55, 180, 181].includes(loanSubStatusId));
  },

  isLoanMLA(state) {
    // loanStatus: 'Loan Documents' = 10
    // loanSubStatus: 'Loan Docs Signed-MLA' = 146
    const { loanStatusId, loanSubStatusId } = state.currentStatus;
    return loanStatusId === 10 && loanSubStatusId === 146;
  },

  lineOfCreditClosed(state) {
    const { loanStatusId, loanSubStatusId } = state.currentStatus;

    // loanStatus: 'Line Closed' = 22
    // loanSubStatusId: 'Active Offer' = 213
    // loanSubStatus: 'Offer Redeemed' = 214
    // loanSubStatus: 'Offer Originated' = 215
    return loanStatusId === 22 && [213, 214, 215].includes(loanSubStatusId);
  },

  completedPayments(state) {
    // past payments sorted from newest to oldest
    return [...state.payments].sort(({ date: left }, { date: right }) => {
      const leftDate = DateTime.fromISO(left);
      const rightDate = DateTime.fromISO(right);
      return rightDate.diff(leftDate);
    });
  },

  futurePayments(state) {
    // future scheduled payments sorted with next payment first
    return state.transactions
      .filter((tx) => tx.future === 1 && tx.type === TransactionTypes.SCHEDULED_PAYMENT)
      .sort(({ date: left }, { date: right }) => {
        const leftDate = DateTime.fromISO(left);
        const rightDate = DateTime.fromISO(right);
        return leftDate.diff(rightDate);
      });
  },

  pendingAutopays(state) {
    return state.autopays
      .filter((autopay) => autopay.status === AutopayStatus.PENDING)
      .sort(({ applyDate: left }, { applyDate: right }) => {
        const leftDate = DateTime.fromISO(left);
        const rightDate = DateTime.fromISO(right);
        return leftDate.diff(rightDate);
      });
  },

  tomorrowAutopays(state, { pendingAutopays }) {
    const tomorrow = DateTime.now().setZone("America/Chicago").plus({ days: 1 }).startOf("day");

    return pendingAutopays.filter((payment) => {
      if (!payment.applyDate) {
        return false;
      }
      const applyDate = DateTime.fromISO(payment.applyDate, { zone: "America/Chicago" });
      return applyDate.hasSame(tomorrow, "day");
    });
  },

  // according to Jira ticket, this is logic that should control AutoPayProcessingNotice
  // HDT-1120
  autopaysForProcessingNotice(state) {
    const now = DateTime.now().setZone("America/Chicago");
    return state.autopays
      .filter((autopay) => {
        const regex = /^\/Date\((-?[0-9]+)\)\/$/;
        const processDateTimeSeconds = Number(regex.exec(autopay.processDateTimeFromLp)[1]);
        const processDateTime = DateTime.fromSeconds(processDateTimeSeconds, { zone: "UTC" })
          .setZone("America/Chicago");
        const applyDate = DateTime.fromISO(autopay.applyDate).setZone("America/Chicago");

        // const processDateIsToday = now.day === processDateTime.day;
        const processDateIsToday = now.hasSame(processDateTime, "day")
          && now.hasSame(processDateTime, "month")
          && now.hasSame(processDateTime, "year");

        const processTimeIsInThePast = processDateTime < now;

        // const applyDateIsToday = now.day === applyDate.day;
        const applyDateIsToday = now.hasSame(applyDate, "day")
          && now.hasSame(applyDate, "month")
          && now.hasSame(applyDate, "year");

        return (
          (autopay.status === AutopayStatus.COMPLETED
          && autopay.type === AutopayTypes.RECURRING
          && processDateIsToday
          && processTimeIsInThePast)
          || applyDateIsToday
        );
      });
  },

  showAutoPayProcessingNotice(state, { autopaysForProcessingNotice }) {
    return autopaysForProcessingNotice.length > 0;
  },

  pendingPayoff(state, { pendingAutopays }) {
    return _find(pendingAutopays, {
      PaymentType: { id: PaymentTypes.PAYOFF },
    });
  },

  recurringAutopays(state) {
    return state.autopays.filter((autopay) => {
      //  check for recuring payments
      const recuringPayment = autopay.type === AutopayTypes.RECURRING || autopay.type === AutopayTypes.RECURRINGMATCH;

      // check for pending p
      const pendingPayment = autopay.status === AutopayStatus.PENDING;

      return recuringPayment && pendingPayment;
    });
  },

  hasRecurringPayment(state, { recurringAutopays }) {
    return recurringAutopays.length > 0;
  },

  isLoanOverdue({ currentStatus: { amountDue } }) {
    return Number(amountDue) > 0;
  },

  amountDue({ currentStatus: { amountDue } }) {
    return amountDue;
  },

  // we use this in the context where customer has paidoff there loan
  // and are in a status of pending final payment
  finalPayment({ currentStatus }, { pendingAutopays }) {
    // check if we have have an autopay payment type equal to payoff
    const finalAutopay = _find(pendingAutopays, {
      PaymentType: { id: PaymentTypes.PAYOFF },
    });

    if (finalAutopay) {
      const { amount: paymentAmount, applyDate: paymentDate } = finalAutopay;
      return { paymentAmount, paymentDate };
    }

    //  check the last payment they made, if we did not find a payoff autopay
    const { lastPaymentAmount, lastPaymentDate } = currentStatus;

    return {
      paymentAmount: lastPaymentAmount,
      paymentDate: lastPaymentDate,
    };
  },

  percentagePaidOff(_, { completedPayments, scheduledPayments }) {
    const totalPaid = completedPayments.reduce((acc, item) => acc + Number(item.paymentAmount), 0);

    const totalRemaining = scheduledPayments.reduce((acc, item) => acc + Number(item.chargeAmount), 0);

    const totalBalance = totalPaid + totalRemaining;

    return Math.round((totalPaid * 100) / totalBalance);
  },

  // future scheduled payment order by ascending date
  scheduledPayments({ transactions }) {
    return transactions
      .filter((tx) => tx.future === 1 && tx.type === "scheduledPayment")
      .sort(({ date: left }, { date: right }) => {
        const leftDate = DateTime.fromISO(left);
        const rightDate = DateTime.fromISO(right);
        return leftDate.diff(rightDate);
      });
  },

  todayPayoffBalance({ currentStatus: { payoff } }) {
    return Number(payoff);
  },

  settlementConfig({ config: { loanId, settingsId } }, { todayPayoffBalance }) {
    return {
      loanId,
      loanSettingsId: settingsId,
      payoffBalance: todayPayoffBalance,
    };
  },

  latestPdsSettlement(state) {
    return state.latestPdsSettlement;
  },

  settlementStatus(_, getters) {
    const {
      pendingAutopays,
      todayPayoffBalance: payoff,
      hasRecurringPayment,
      scheduledPayments,
      latestPdsSettlement,
    } = getters;

    // check for the next settlement autopay
    const nextAutopay = _find(pendingAutopays, ({ name }) => name.toLowerCase().includes("settlement"));

    return {
      payoff,
      hasRecurringPayment,
      latestPdsSettlement,
      paymentDate: (hasRecurringPayment) ? _get(nextAutopay, "applyDate", "") : scheduledPayments[0].date,
      paymentAmount: (hasRecurringPayment) ? _get(nextAutopay, "amount", 0) : scheduledPayments[0].chargeAmount,
    };
  },

  matchNextInstallementDueDate:
    (state, { hasRecurringPayment }) => (date) => {
      const {
        currentStatus: { nextPaymentDate },
      } = state;

      return hasRecurringPayment && nextPaymentDate === date;
    },
};
