import Vue from "vue";

export default {
  getCustomer() {
    return Vue.prototype.$axios
      .get("api/v1/customer")
      .catch(this.errorHandler);
  },

  getDocument(documentId) {
    const endpoint = documentId === -1 ? "api/v1/privacy-policy/" : "api/v1/document/";
    return Vue.prototype.$axios
      .get(`${endpoint}${documentId}`, { responseType: "arraybuffer" })
      .catch(this.errorHandler);
  },

  updatePassword(password) {
    return Vue.prototype.$axios
      .put("api/v1/customer/password", { password })
      .catch(this.errorHandler);
  },

  updatePrimaryPaymentAccount(accountId) {
    return Vue.prototype.$axios
      .put("api/v1/customer/primary-payment-account", { accountId })
      .catch(this.errorHandler);
  },

  addBankAccount(token, bankAccountType, title) {
    return Vue.prototype.$axios
      .post("api/v1/customer/bank-account", { token, bankAccountType, title })
      .catch(this.errorHandler);
  },

  addDebitCard(token, title) {
    return Vue.prototype.$axios
      .post("api/v1/customer/debit-card", { token, title })
      .catch(this.errorHandler);
  },

  errorHandler(error) {
    if (error.response) {
      return error.response.data;
    }
    if (error.request) {
      return error.request;
    }
    return error.message;
  }
};
