import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faCheckCircle, faComment, faCommentDots, faDownload, faChevronLeft, faChevronRight, faCheck
} from "@fortawesome/free-solid-svg-icons";

library.add([faCheckCircle, faComment, faCommentDots, faDownload, faChevronLeft, faChevronRight, faCheck]);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);

// https://github.com/FortAwesome/vue-fontawesome
