<template>
  <div v-if="show">
    <div
      class="popup-background"
      :style="{ 'z-index': hasBackground ? '1043' : null }"
    />
    <div
      class="popup-wrapper"
      @click="close"
    >
      <div class="popup-container">
        <div
          id="modal"
          class="popup-content"
          :style="{ 'max-width': wide ? '760px' : null }"
          role="dialog"
          aria-modal="true"
          aria-labelledby="popup-header"
          @click.stop
        >
          <div class="popup-block">
            <button
              type="button"
              aria-label="Close modal"
              title="Close (Esc)"
              class="popup-close"
              @click="close"
            >
              X
            </button>

            <div class="popup-holder">
              <div
                v-if="$slots.header"
                id="popup-header"
                class="popup-header"
              >
                <slot name="header" />
              </div>

              <div>
                <slot />
              </div>

              <div class="popup-bottom">
                <slot name="footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      focusableElements: "button, [href], input, select, textarea, [tabindex]:not([tabindex=\"-1\"])",
      modalElement: null
    };
  },
  computed: {
    focusableContent() {
      return this.modalElement ? this.modalElement.querySelectorAll(this.focusableElements) : null;
    },
    lastFocusableElement() {
      return this.focusableContent ? this.focusableContent[this.focusableContent.length - 1] : null;
    },
    firstFocusableElement() {
      return this.modalElement ? this.modalElement.querySelectorAll(this.focusableElements)[0] : null;
    },
    hasBackground() {
      // if a modal is already open we want to increase the z-index of the background
      return !!document.querySelectorAll(" div .popup-background")[0];
    }
  },

  watch: {
    show: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this[newVal ? "open" : "close"]();
        }
      }
    }
  },

  mounted() {
    this[this.$props.show ? "open" : "close"]();
  },

  destroyed() {
    // was having an issue where popup is not close
    // after router redirect, this fix that
    this.$el.remove();
  },

  methods: {
    open() {
      this.$emit("open");

      // wait for nextTick because element displays after prop change
      this.$nextTick(() => {
        this.addEventListener();
      });

      // append the popup to body node so mask covers header
      document.body.appendChild(this.$el);
    },
    close() {
      this.$emit("close");
      document.removeEventListener("keydown", this.makeElementsFocusable);
    },
    makeElementsFocusable(event) {
      const isTabPressed = event.key === "Tab";
      const isEscPressed = event.key === "Escape";

      if (!isTabPressed && !isEscPressed) {
        return;
      }

      // Tab through elements in the modal
      if (isTabPressed) {
        if (event.shiftKey) {
          if (document.activeElement === this.firstFocusableElement) {
            this.lastFocusableElement.focus();
            event.preventDefault();
          }
        } else if (document.activeElement === this.lastFocusableElement) {
          this.firstFocusableElement.focus();
          event.preventDefault();
        }
      }

      // close modal on escape key
      if (isEscPressed) {
        this.close();
      }
    },
    addEventListener() {
      this.modalElement = document.querySelector("#modal");
      // focus on first element in popup now that element is rendered
      if (this.firstFocusableElement) {
        this.firstFocusableElement.focus();
      }
      // and apply the event listener
      document.addEventListener("keydown", this.makeElementsFocusable);
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-background {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.popup-wrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10043;
  position: absolute;
  outline: none;
  -webkit-backface-visibility: hidden;
}

.popup-container {
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 15px;
  box-sizing: border-box;
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.popup-content {
  position: relative;
  display: inline-block;
  vertical-align: sub;
  text-align: left;
  z-index: 1045;
  width: 100%;
  max-width: 660px;
  margin-top: 50px;
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
  & a {
    text-decoration: underline;
  }
}

.popup-block {
  overflow-y: scroll;
  background-color: #fff;
  padding: 20px 15px;
  border: 2px solid $popup-border-color;
}

.popup-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

button.popup-close {
  overflow: visible;
  cursor: pointer;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  width: 35px;
  height: 35px;
  background-color: #1d1d1b;
  border: 1px solid #fff;
  border-radius: 50%;
  opacity: 1;
  color: #fff;
  font-size: 23px;
  line-height: 35px;
  right: -10px;
  top: -10px;
}

.popup-header {
  color: #4d4d4d !important;
  border-bottom: 1.5px solid #e6e7e8;
  margin-bottom: 20px !important;
  & h1 {
    font-size: 2.6em;
    margin-bottom: 14px;
  }
}

.popup-holder {
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 15px;
  & h1 {
    font-size: 48px;
    font-weight: normal;
  }
  & h2 {
    font-size: 42px;
    line-height: 1.2;
  }
  & h3 {
    font-size: 34px;
    font-weight: normal;
  }
  & .form-group {
    margin-bottom: 15px;
  }
  & .ttl-feedback {
    padding: 30px 0 0;
    font-size: 23px;
    line-height: 1.2;
  }
  & .feedback-bottom p {
    margin: 0;
  }
}

@media (max-width: 1023px) {
  .popup-holder h1 {
    font-size: 32px;
  }

  .popup-holder h2 {
    font-size: 28px;
  }

  .popup-holder h3 {
    font-size: 28px;
  }
}

@media (max-width: 666px) {
  .popup-holder h3 {
    font-size: 24px;
  }
}

@media (max-width: 479px) {
  .popup-header h1 {
    font-size: 2em;
  }

  .popup-header p {
    font-size: 0.9em;
  }
}
</style>
