import { loanMutations } from "@secure/store/mutationTypes";
import LpLoan from "@secure/services/LpLoanService";

export default {
  init({ commit }, loan) {
    commit(loanMutations.SET_LOAN_CONFIG, loan);
  },

  async fetchCurrentStatus({ state, commit }) {
    const status = await LpLoan.getCurrentStatus(state.config.loanId);

    if (status.loanId) {
      commit(loanMutations.SET_CURRENT_STATUS, status);
    }
  },

  async fetchLatestStatement({ state, commit }) {
    const statement = await LpLoan.getLatestStatement(state.config.loanId);

    if (statement && Object.keys(statement).length > 0) {
      commit(loanMutations.SET_LATEST_STATEMENT, statement);
    }
  },

  async fetchLatestPdsSettlement({ state, commit }) {
    const pdsSettlement = await LpLoan.getLatestPdsSettlement(state.config.loanId);

    if (pdsSettlement && Object.keys(pdsSettlement).length > 0) {
      commit(loanMutations.SET_LATEST_PDS_SETTLEMENT, pdsSettlement);
    }
  },

  async fetchPayments({ state, commit }) {
    const { loanId } = state.config;
    const payments = await LpLoan.getPayments(loanId);

    if (Array.isArray(payments)) {
      commit(loanMutations.SET_PAYMENTS, payments);
    }
  },

  async fetchTransactions({ state, commit }) {
    const { loanId } = state.config;
    const transactions = await LpLoan.getTransactions(loanId);

    if (Array.isArray(transactions)) {
      commit(loanMutations.SET_TRANSACTIONS, transactions);
    }
  },

  async fetchAutopays({ state, commit }) {
    const { loanId } = state.config;
    const result = await LpLoan.getAutopays(loanId);

    if (Array.isArray(result)) {
      commit(loanMutations.SET_AUTOPAYS, result);
    }
  },

  async fetchPaymentSchedules({ state, commit }) {
    const { loanId } = state.config;
    const result = await LpLoan.getOrigPaymentSchedules(loanId);

    if (Array.isArray(result)) {
      commit(loanMutations.SET_PAYMENT_SCHEDULES, result);
    }
  },

  setBusinessDays({ commit }, days) {
    commit(loanMutations.SET_BUSINESS_DAYS, days);
  },

  async setDebtCompany({ state, commit }) {
    const { loanId } = state.config;

    const debtCompany = await LpLoan.getDebtCompany(loanId);
    commit(loanMutations.SET_DEBT_COMPANY, debtCompany);
  },
};
