const lineOfCredit = () => import(/* webpackChunkName: "lineOfCredit" */ "./modules/lineOfCredit/index");
const installment = () => import(/* webpackChunkName: "installment" */ "./modules/installment/index");

export default {
  async registerProductModule(store, router) {
    const { productLoanType } = store.state;

    if (!productLoanType) {
      const location = window.location.href;
      if (location.includes(".com/login?url=")
        && location.includes(".com/api/v1/customer/signed-password-reset/")
        && location.includes("?expires=")
        && location.includes("&signature=")
      ) {
        return;
      }

      if (location.includes(".com/direct-mail")) {
        return;
      }

      router.push({ name: "login" });

      return;
    }

    const link = router.resolve({ name: "active-loan-dashboard" });
    // console.log("resolved", link.resolved.matched.length > 0);
    // Prevent: Duplicate named routes definition
    if (link.resolved.matched.length === 0) {
      let activeModule = null;

      if (store.getters.isLineOfCredit) {
        activeModule = await lineOfCredit();
      } else {
        activeModule = await installment();
      }

      const { routes } = activeModule.default;

      if (Array.isArray(routes)) {
        routes.forEach((route) => router.addRoute(route));
      } else {
        router.addRoute(routes);
      }

      store.commit("setProductMenus", activeModule.default.menus);
    }
  },
};
