import Vue from "vue";
import Vuex from "vuex";
import LpLoan from "@secure/services/LpLoanService";
import lodashGet from "lodash/get";

import modules from "./modules";
import plugins from "./plugins";

Vue.use(Vuex);

const nonProdEnviroment = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  strict: nonProdEnviroment,
  state: {
    qaFeatureEnabled: process.env.VUE_APP_QA_FEATURE_ENABLED === "true",
    productMenus: [],
    productLoanId: null,
    productLoanType: null,
    returnOfferProductType: null,
  },
  modules,
  plugins,
  actions: {
    async init({ dispatch }, loanId) {
      const loanData = await LpLoan.getLoanData(loanId);

      if (loanData) {
        const customerData = lodashGet(loanData, "Customers.results[0]");

        await dispatch("customer/init", customerData);
        await dispatch("loan/init", loanData);
        await dispatch("loan/fetchCurrentStatus");
        await dispatch("loan/fetchLatestStatement");
        await dispatch("loan/fetchLatestPdsSettlement");
        await dispatch("loan/fetchAutopays");
        await dispatch("loan/fetchPayments");
        await dispatch("loan/fetchTransactions");
        await dispatch("loan/setDebtCompany");
      }
    },
  },
  mutations: {
    changeTestingState(state, payload) {
      if (state.qaFeatureEnabled) {
        const { currentStatus } = state.loan;
        state.loan.currentStatus = { ...currentStatus, ...payload };

        if ("transactions" in payload) {
          state.loan.transactions = [...payload.transactions];
        }

        if ("pendingAutopays" in payload) {
          state.loan.autopays = [...payload.pendingAutopays];
        }
      }
    },
    setProduct(state, payload = {}) {
      // eslint-disable-next-line camelcase
      state.productLoanId = payload?.loan_id;
      // eslint-disable-next-line camelcase
      state.productLoanType = payload?.loan_type;
    },
    setProductMenus(state, menus) {
      state.productMenus = menus;
    },
    setReturnOfferProductType(state, productType) {
      state.returnOfferProductType = productType;
    },
  },
  getters: {
    allowedMenus(state, getters) {
      let { productMenus } = state;

      const isLoanSoldOff = getters["loan/isLoanSoldOff"] ?? false;
      const isLoanRescinded = getters["loan/isLoanRescinded"] ?? false;
      const isLoanMLA = getters["loan/isLoanMLA"] ?? false;
      const isLoanPendingFunding = getters["loan/isLoanPendingFunding"] ?? false;
      const lineOfCreditClosed = getters["loan/lineOfCreditClosed"] ?? false;
      const hasNewInstallmentOffer = getters["loan/hasNewInstallmentOffer"] ?? false;
      const isEligibleForInstallmentOffer = getters["loan/isEligibleForInstallmentOffer"] ?? false;
      const productDiscontinued = getters["customer/productDiscontinued"] ?? false;
      const isOrrickBrand = getters["customer/isOrrickBrand"] ?? false;

      // Run first filter to remove "help-center" if productDiscontinued is true
      // Run second filter to remove "active-loan-request-funds" if not eligible for installment offer
      productMenus = productMenus
        .filter((link) => {
          if (productDiscontinued) {
            return link.name !== "help-center";
          }
          return true;
        })
        .filter((link) => {
          if (!isEligibleForInstallmentOffer) {
            return link.name !== "active-loan-request-funds";
          }
          return true;
        });

      // If line of credit is closed or installment offer is redeemed, filter out allowed links
      if (lineOfCreditClosed || hasNewInstallmentOffer) {
        console.log("allowedMenus: lineOfCreditClosed || hasNewInstallmentOffer");

        const allowedLinks = [
          "active-loan-dashboard",
          "active-loan-loan-agreements",
          "active-loan-statements",
          "user-profile",
          "help-center",
          "logout",
          "testing",
        ];

        if (lineOfCreditClosed.activeOffer) {
          allowedLinks.push("active-loan-request-funds");
        }

        return productMenus.filter((link) => allowedLinks.includes(link.name));
      }

      // If loan is sold off, filter out allowed links
      if (isLoanSoldOff) {
        console.log("allowedMenus: isLoanSoldOff");

        const allowedLinks = [
          "active-loan-dashboard",
          "active-loan-loan-agreements",
          "help-center",
          "logout",
          "testing",
        ];
        return productMenus.filter((link) => allowedLinks.includes(link.name));
      }

      // If loan is rescinded or MLA, filter out allowed links
      if (isLoanRescinded || isLoanMLA) {
        console.log("allowedMenus: isLoanRescinded || isLoanMLA");
        const allowedLinks = ["active-loan-dashboard", "help-center", "logout", "testing"];
        return productMenus.filter((link) => allowedLinks.includes(link.name));
      }

      // If loan is pending funding, filter out not allowed links
      if (isLoanPendingFunding) {
        console.log("allowedMenus: isLoanPendingFunding");
        const notAllowedLinks = ["active-loan-request-funds", "active-loan-payments", "active-loan-payment-schedules"];
        return productMenus.filter((link) => !notAllowedLinks.includes(link.name));
      }

      if (isOrrickBrand) {
        const notAllowedLinks = ["active-loan-request-funds"];

        return productMenus.filter((link) => !notAllowedLinks.includes(link.name));
      }

      return productMenus;
    },

    isLineOfCredit(state) {
      return state.productLoanType === "loan.type.creditLimit";
    },

    isReturnOfferLineOfCredit(state) {
      return state.returnOfferProductType === "lineOfCredit";
    },
  },
});
