import axios from "axios";
import router from "./router";
import store from "./store";

const http = axios.create({
  baseURL: process.env.VUE_APP_UNDERWRITING_URL,
  withCredentials: true,
});

http.interceptors.response.use(
  (response) => {
    if (response.headers["x-maintenance-mode"] === "true") {
      router.push({ name: "maintenance" });
    }

    return Promise.resolve(response);
  },
  (error) => {
    // log user out if 401 or 419 is returned (or 503 for maintenance mode)
    if (error.response && [419, 401, 503].includes(error.response.status)) {
      store.commit("auth/AUTH_LOGOUT");

      if (error.response.status === 503) {
        store.commit("auth/SET_MAINTENANCE", true);
        router.push({ name: "maintenance" });
      } else if (!["login", "directMailLogin"].includes(router.currentRoute.name)) {
        router.push({ name: "login" });
      }
    }

    return Promise.reject(error);
  }
);

http.interceptors.request.use(
  (request) => {
    const reqClone = { ...request };
    // eslint-disable-next-line camelcase
    const leadId = store.state?.customer?.leadInfo?.lead_id;

    if (leadId) {
      reqClone.headers["X-Lead-Id"] = leadId;
    }

    return reqClone;
  },
  (error) => Promise.reject(error)
);

export default http;
