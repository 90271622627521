import { loanMutations } from "@secure/store/mutationTypes";
import _pick from "lodash/pick";

export default {
  [loanMutations.SET_PAYMENT_SCHEDULES]: (state, value) => {
    state.paymentSchedules = value;
  },

  [loanMutations.SET_LOAN_CONFIG]: (state, loan) => {
    const loanInfo = _pick(loan, ["displayId", "settingsId", "setupId"]);
    const loanSetup = _pick(loan.LoanSetup, [
      "loanId",
      "apr",
      "tilFinanceCharge",
      "tilTotalOfPayments",
      "tilLoanAmount",
      "loanAmount",
      "loanType",
      "loanRate",
      "paymentFrequency",
    ]);

    // numeric casting
    ["loanId", "apr", "tilFinanceCharge", "tilTotalOfPayments", "tilLoanAmount", "loanAmount", "loanRate"].forEach(
      (item) => {
        loanSetup[item] = Number(loanSetup[item]);
      }
    );

    state.config = { ...loanInfo, ...loanSetup };
  },

  [loanMutations.SET_CURRENT_STATUS]: (state, payload) => {
    const currentStatus = { ...payload };

    // numeric casting
    [
      "amountDue",
      "nextPaymentAmount",
      "lastPaymentAmount",
      "principalBalance",
      "amountPastDue30",
      "daysPastDue",
      "dueInterest",
      "duePrincipal",
      "payoff",
      "loanStatusId",
      "loanSubStatusId",
      "availableCredit",
      "creditLimit",
      "pendingAdvanceAmount",
    ].forEach((item) => {
      currentStatus[item] = Number(currentStatus[item]);
    });

    state.currentStatus = currentStatus;
  },

  [loanMutations.SET_AUTOPAYS]: (state, autopays) => {
    state.autopays = autopays;
  },

  [loanMutations.SET_PAYMENTS]: (state, payments) => {
    state.payments = payments;
  },

  [loanMutations.SET_TRANSACTIONS]: (state, transactions) => {
    state.transactions = transactions;
  },

  [loanMutations.SET_BUSINESS_DAYS]: (state, days) => {
    state.datePickerConfig.enable = days;
  },
  [loanMutations.SET_DEBT_COMPANY]: (state, company) => {
    state.debtCompany.name = (company) ? company.name : "";

    let address1 = "";
    if (company.address) {
      address1 += company.address;
    }

    if (company.address2) {
      address1 += `, ${company.address2}`;
    }
    address1 += ".";

    state.debtCompany.address1 = address1;

    let address2 = "";
    if (company.city) {
      address2 += `${company.city}`;
    }

    if (company.state) {
      address2 += ` ${company.state}`;
    }

    if (company.zip) {
      address2 += `, ${company.zip}`;
    }
    state.debtCompany.address2 = address2;

    state.debtCompany.phone = (company) ? company.phone : "";
    state.debtCompany.email = (company) ? company.email : "";
    state.debtCompany.website = (company) ? company.website : "";
  },

  [loanMutations.SET_LATEST_STATEMENT]: (state, statement) => {
    state.latestStatement = statement;
  },

  [loanMutations.SET_LATEST_PDS_SETTLEMENT]: (state, settlement) => {
    state.latestPdsSettlement = settlement;
  },
};
