<template>
  <div class="not-found">
    <h1 class="not-found__header">
      Oops, something went wrong...
    </h1>
    <p class="not-found__text">
      We were unable to load the page settings, please try again
    </p>
  </div>
</template>

<script>
export default {
  name: "NoSettings",

};
</script>

<style lang="scss" scoped>
.not-found {
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background-color: $fullloader-bg-color;
  &__header {
    font-size: 70px;
    font-weight: bold;
  }
  &__text {
    font-size: 30px;
    & a {
      color: #fff !important;
    }
  }
}

</style>
