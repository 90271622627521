<template>
  <div>
    <HeadTitle :title="`Client Login - ${settings.company_long_name}`" />
    <div class="row-section pl-xs pr-xs pb-0">
      <h3 class="title pb-xs">
        Account Login
      </h3>
    </div>

    <div class="row-section p-xs">
      <div class="row">
        <div class="col-md-12">
          <FormGroup label="Email">
            <TextInput
              v-model="form.username"
              type="email"
              class="form-control form-control-lg"
              required
            />
            <template
              v-if="formSubmitted"
              #error
            >
              <i v-if="!$v.form.username.required">Email is required.</i>
              <i v-if="!$v.form.username.email">Must be a valid email.</i>
            </template>
          </FormGroup>
        </div>

        <div class="col-md-12 mt-s">
          <FormGroup label="Password">
            <InputGroup>
              <TextInput
                v-model="form.password"
                :type="passwordInputType"
                class="form-control form-control-lg fs-exclude"
                required
              />

              <template #icon>
                <img
                  src="@secure/assets/images/icon-view.png"
                  @click="togglePasswordInputType"
                >
              </template>
            </InputGroup>
            <template
              v-if="formSubmitted"
              #error
            >
              <i v-if="!$v.form.password.required">Password is required.</i>
              <i v-if="!$v.form.password.minLength">
                Password must have {{ $v.form.password.$params.minLength.min }} characters.
              </i>
            </template>
          </FormGroup>
        </div>

        <div class="col-md-12 mt-s">
          <FormGroup>
            <Recaptcha
              @verifyRecaptcha="onVerifyRecaptcha"
              @recaptchaExpired="onRecaptchaExpired"
            />
            <template
              v-if="formSubmitted"
              #error
            >
              <i v-if="!$v.recaptchaVerified.required">Recaptcha is required.</i>
            </template>
          </FormGroup>
        </div>

        <div
          v-if="settings.show_login_privacy_and_term_text"
          class="col-md-12 mt-s"
        >
          By signing in to the Client Portal, you agree to our
          <a
            :href="settings.link_terms_of_use_href"
            target="_blank"
          > Terms of Use </a>
          and
          <a
            :href="settings.link_privacy_policy_href"
            target="_blank"
          > Privacy Policy </a>.
        </div>

        <div class="col-md-12">
          <span
            v-if="errorMessage"
            class="help-block with-errors"
          >
            {{ errorMessage }}
          </span>
        </div>

        <div
          v-if="loading"
          class="col-md-12 loader-container"
        >
          <Loader />
        </div>
        <span v-else>
          <div class="col-md-6 mt-s">
            <BaseButton
              :disabled="!recaptchaVerified"
              button-full
              size="large"
              color="inverse"
              @click="handleLogin"
            >
              SIGN IN
            </BaseButton>
          </div>
          <div class="col-md-6 mt-s">
            <BaseButton
              button-full
              size="large"
              @click="sendRequestModalOpen = true"
            > RESET PASSWORD </BaseButton>
          </div>
        </span>
        <div style="color: rgba(0, 0, 0, 0.12); text-align: center; font-size: 0.8rem; margin-top: 0.5rem">
          {{ version }}
        </div>
      </div>
    </div>

    <div class="text-advices p-xs">
      <h5>Having trouble logging in?</h5>
      <p>1. Remember, passwords are case sensitive!</p>
      <p>2. Make sure you are using the email address associated with your account.</p>
      <p>
        3. We recommend using the latest version of Chrome, Firefox, or Safari. Internet Explorer is no longer
        supported.
      </p>
      <h5 class="mt-s">
        Still having issues?
      </h5>
      <p>
        Call
        <a :href="`tel:${settings.company_phone}`"> {{ settings.company_phone }} </a>, Monday-Friday, 8am-5pm (Central
        Time) or email
        <a :href="`mailto:${settings.company_email}`">
          {{ settings.company_email }}
        </a>
        for assistance.
      </p>
    </div>

    <!-- showIphoneHelpPopup -->
    <Popup
      :show="showIphoneHelpPopup"
      @close="showIphoneHelpPopup = false"
    >
      <img :src="$settings_asset(settings.asset_login_iphone_help_img_1)">

      <hr>

      <h4>Still having issues?</h4>
      <p>
        Call
        <a :href="`tel:${settings.company_phone}`">
          {{ settings.company_phone }}
        </a>
        , Monday-Friday, 8am-5pm (Central Time) or email
        <a :href="`mailto:${settings.company_email}`">
          {{ settings.company_email }}
        </a>
        for assistance.
      </p>
    </Popup>
    <!-- showIphoneHelpPopup -->

    <LoginSendResetRequestModal
      :open="sendRequestModalOpen"
      @close="sendRequestModalOpen = false"
    />

    <LoginResetPasswordModal
      v-if="resetPasswordUrl"
      :open="resetPasswordModalOpen"
      :url="resetPasswordUrl"
      @close="resetPasswordModalOpen = false"
    />

    <TemporarilyBlockedModal
      :message="blockedMessage"
      @reset-block-message="resetBlockMessage"
    />

    <VerificationModal
      :open="verificationModalOpen"
      :verification-methods="verificationMethods"
      @close="onCloseVerificationModal"
      @valid-code="onValidCode"
      @invalid-code="onInvalidCode"
    />
  </div>
</template>

<script>
/* eslint global-require: 0, no-param-reassign: 0, import/no-dynamic-require: 0 */
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import loadashGet from "lodash/get";
import LoginSendResetRequestModal from "@secure/pages/Login/LoginSendResetRequestModal.vue";
import LoginResetPasswordModal from "@secure/pages/Login/LoginResetPasswordModal.vue";
import VerificationModal from "@secure/components/VerificationModal.vue";
import Bugsnag from "@bugsnag/js";
import moduleLoader from "@secure/moduleLoader";
import Recaptcha from "@/components/Recaptcha.vue";
import TemporarilyBlockedModal from "@secure/pages/Login/TemporarilyBlockedModal.vue";

export default {
  name: "Login",
  components: {
    TemporarilyBlockedModal,
    Recaptcha,
    LoginSendResetRequestModal,
    LoginResetPasswordModal,
    VerificationModal,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      formSubmitted: false,
      showIphoneHelpPopup: false,
      passwordInputType: "password",
      recaptchaVerified: null,
      errorMessage: null,
      loading: false,
      sendRequestModalOpen: false,
      resetPasswordModalOpen: false,
      resetPasswordUrl: null,
      verificationModalOpen: false,
      verificationMethods: [],
      blockedMessage: "",
    };
  },

  computed: {
    ...mapState("customer", ["leadInfo", "settings"]),
    ...mapState("auth", ["version"]),
  },

  created() {
    const query = window.location.search;
    if (query && query.startsWith("?url=")) {
      const uri = query.substring(5);
      this.resetPasswordModalOpen = true;
      this.resetPasswordUrl = decodeURIComponent(uri);
    }
  },

  async mounted() {
    await this.$axios.post("api/v1/customer-login-metric/track-page-view");
  },

  methods: {
    ...mapActions("customer", ["sendVerificationCode", "setLeadInfo"]),
    ...mapActions("auth", ["handleLoginSuccess"]),

    togglePasswordInputType() {
      this.passwordInputType = this.passwordInputType === "password" ? "text" : "password";
    },

    resetBlockMessage() {
      this.blockedMessage = "";
    },

    handleLogin() {
      this.formSubmitted = true;
      this.errorMessage = null;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loading = true;

        this.$axios
          .get("sanctum/csrf-cookie")
          .then(() => this.$axios.post("customer/login", this.form))
          .then(async (response) => {
            if (response.status === 200) {
              if (response.data && response.data.identityTheft === true) {
                this.$router.push("/identity-theft");
              } else {
                this.setLeadInfo(loadashGet(response, "data.data"));

                this.$store.commit("setProduct", response.data.data);

                this.pushGtmDataLayer();

                let hasPhoneMethod = false;
                let verificationEmail = "";
                this.verificationMethods = response.data.data.verificationMethods.map((method) => {
                  if (method.type === "phone") {
                    hasPhoneMethod = true;
                  }
                  // we can determine email like this since there is only one email verificationOption
                  if (method.type === "email") {
                    verificationEmail = method.value;
                  }
                  const methodTitle = this.sanitizeMethod(method);
                  return {
                    title: methodTitle,
                    ...method,
                  };
                });
                this.verificationModalOpen = true;

                // send verification via email if there are no phone options which could also mean sms is disabled
                // before SMS 2FA our verification code was sent here with no catch, leaving for now
                if (!hasPhoneMethod) {
                  this.sendVerificationCode(verificationEmail);
                }
              }
            }
          })
          .then(async () => {
            await moduleLoader.registerProductModule(this.$store, this.$router);
          })
          .catch((error) => {
            let message = "Error, something went wrong.";

            if (error.response && (error.response.status === 401 || error.response.status === 409)) {
              message = error.response.data.message;
              this.loading = false;
              this.formSubmitted = false;
              this.form.password = "";
            } else {
              if (error.response && (error.response.status === 500 && error.response.data.attempts_message)) {
                this.blockedMessage = error.response.data.attempts_message;
                this.loading = false;
                this.formSubmitted = false;
                this.form.password = "";
              }

              Bugsnag.notify(error);
            }

            this.errorMessage = message;
          });
      }
    },

    sanitizeMethod(method) {
      if (method.type === "phone") {
        return `***-***-**${method.value.slice(-2)}`;
      }
      if (method.type === "email") {
        const lastSix = method.value.slice(-6);
        const first = method.value.charAt(0);
        const replaceCharString = method.value.substr(1, method.value.length - 7).replace(/[^@]/g, "*");

        return `${first}${replaceCharString}${lastSix}`;
      }
    },

    onCloseVerificationModal() {
      this.loading = false;
      this.verificationModalOpen = false;
    },

    async onValidCode() {
      this.loading = false;
      await this.handleLoginSuccess(this.$axios);
      this.$router.push({ name: "active-loan-dashboard" });
    },

    async onInvalidCode() {
      await this.$axios.post("api/v1/customer-login-metric/track-incorrect-2fa-code");
    },

    onVerifyRecaptcha(token) {
      // backend returns boolean after checking this token against the secret key
      if (token) {
        this.$axios
          .get(`customer/recaptcha-verify/${token}`)
          .then((response) => {
            this.recaptchaVerified = response.data;
          })
          .catch(() => {
            this.recaptchaVerified = null;
          });
      }
    },

    onRecaptchaExpired() {
      this.recaptchaVerified = null;
    },

    pushGtmDataLayer() {
      if (this.$gtm.enabled()) {
        // GTM dataLayer.push lead data
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          lead_guid: this.leadInfo.lead_guid,
          lead_id: this.leadInfo.lead_id,
          email: this.leadInfo.email,
          cust_id: this.leadInfo.guid,
        });
      }
      // Explicit FS.identify call if we have it in the window namespace
      const clearIntervalId = setInterval(() => {
        if (window.FS && window.FS.identify) {
          // eslint-disable-next-line no-console
          console.info("Login -> pushGtmDataLayer -> Calling FS.identify");
          window.FS.identify(this.leadInfo.lead_id, {
            email: this.leadInfo.email,
          });
          clearTimeout(clearIntervalId);
        }
      }, 3);
    },
  },
  validations: {
    form: {
      username: { required, email },
      password: {
        required,
        minLength: minLength(10),
      },
    },
    recaptchaVerified: { required },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 500;
  color: $title-color;
  font-size: 2.2em;
}

.loader-container {
  height: 60px;
  margin-top: 24px;
}

.text-advices {
  color: $title-color;
}
</style>
