<template>
  <div>
    <Popup
      :show="open"
      @close="close"
    >
      <template #header>
        <div class="mb-xs">
          <h1>Reset Password</h1>
        </div>
      </template>

      <form>
        <div class="row">
          <div class="col-sm-12">
            <p>Please enter the code from the email, and confirm a new password.</p>
            <FormGroup label="Code">
              <PasswordResetCodeInput
                v-model="code"
                type="text"
                placeholder="Enter code..."
                class="form-control form-control-lg"
                required
              />
              <template
                v-if="submitted"
                #error
              >
                <i v-if="!$v.code.required">Code is required.</i>
              </template>
            </FormGroup>

            <FormGroup label="Password">
              <TextInput
                v-model="password"
                placeholder="Enter new password..."
                class="form-control form-control-lg fs-exclude"
                required
              />
              <template
                v-if="submitted"
                #error
              >
                <i v-if="!$v.password.required">Password is required.</i>
                <i v-if="!$v.password.minLength">
                  Password must have {{ $v.password.$params.minLength.min }} characters.
                </i>
                <i v-if="!$v.password.passwordRegex">
                  Password must have one lowercase and one uppercase letter, a number, and a special character.
                </i>
              </template>
            </FormGroup>
            <FormGroup label="Confirm Password">
              <TextInput
                v-model="passwordConfirm"
                placeholder="Confirm password..."
                class="form-control form-control-lg fs-exclude"
                required
              />
              <template
                v-if="submitted"
                #error
              >
                <i v-if="!$v.passwordConfirm.sameAsPassword">Password must be identical.</i>
              </template>
            </FormGroup>
          </div>
        </div>
      </form>

      <template #footer>
        <div class="row">
          <div class="col-md-12">
            <Loader v-if="loading" />
            <div
              v-else-if="message"
              class="mt-s mb-s"
            >
              <strong :style="{ color: isError ? '#d74e38' : null }">{{ message }}</strong>
            </div>
            <BaseButton
              v-else
              class="mt-s"
              button-full
              size="large"
              color="inverse"
              @click="submit"
            >
              Submit
            </BaseButton>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import {
  helpers, minLength, required, sameAs
} from "vuelidate/lib/validators";

export default {
  name: "LoginResetPasswordModal",

  props: {
    open: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      code: null,
      password: null,
      passwordConfirm: null,
      submitted: false,
      loading: false,
      message: "",
      isError: false
    };
  },

  methods: {
    submit() {
      this.submitted = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.submitted = false;
        this.loading = true;

        const payload = {
          code: this.code,
          password: this.password,
          password_confirmation: this.passwordConfirm
        };
        this.$axios
          .get("sanctum/csrf-cookie")
          .then(() => this.$axios
            .post(this.url, payload)
            .then(response => {
              if (response.status === 200) {
                // eslint-disable-next-line max-len
                this.message = "Your password has been reset. Please close this window and log in with your new password.";
              } else {
                this.errorMessage();
              }
            })
            .catch(() => {
              this.errorMessage();
            })
            .then(() => { this.loading = false; }));
      }
    },

    close() {
      this.$emit("close");
    },

    errorMessage() {
      this.isError = true;
      this.message = "There was an error. Please generate a new code and try again.";
    }
  },

  validations() {
    const passwordRegex = helpers.regex("passwordRegex", /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/);
    return {
      code: { required },
      password: {
        required,
        passwordRegex,
        minLength: minLength(10)
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
    };
  },
};
</script>
