/* eslint global-require: 0, no-param-reassign: 0, import/no-dynamic-require: 0 */

const PrivateLabelLoader = {
  install(Vue) {
    // This will get us all the private assets base on settings
    Vue.prototype.$settings_asset = name => require(`./${process.env.VUE_APP_INSTANCE_ID}/assets/${name}`);
  }
};

export default PrivateLabelLoader;
