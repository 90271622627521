// Initialize tdirective.
import Inputmask from "inputmask";

export default (el, binding) => {
  let maskOpts = null;
  const { type } = binding.value;

  if (type === "numeric") {
    maskOpts = {
      alias: "numeric",
      groupSeparator: ",",
      autoGroup: true,
      autoUnmask: true,
      digitsOptional: true,
      placeholder: "0",
      rightAlign: false,
      allowPlus: false,
      allowMinus: false
    };
  } else if (type === "money") {
    maskOpts = {
      alias: "numeric",
      groupSeparator: ",",
      autoGroup: true,
      autoUnmask: true,
      digits: 2,
      digitsOptional: false,
      prefix: "$ ",
      placeholder: "0",
      rightAlign: false,
      allowPlus: false,
      allowMinus: false,
      clearMaskOnLostFocus: false,
    };
  } else if (type === "us-date") {
    maskOpts = {
      mask: "99/99/9999",
      placeholder: "MM/DD/YYYY"
    };
  } else if (type === "ssn") {
    maskOpts = {
      mask: "999-99-9999",
      autoUnmask: true,
      rightAlign: false
    };
  } else if (type === "us-phone") {
    maskOpts = {
      mask: "(999) 999-9999",
      rightAlign: false
    };
  } else if (type === "name") {
    maskOpts = {
      regex: "[a-za-zA-Z0-9 ]+",
      rightAlign: false,
      placeholder: "",
    };
  } else if (type === "accountNumber") {
    maskOpts = {
      regex: "^[0-9X]{6,20}$",
      rightAlign: false,
      placeholder: "",
    };
  } else if (type === "routingNumber") {
    maskOpts = {
      regex: "^[0-9]{9}$",
      rightAlign: false,
      placeholder: "",
    };
  } else if (type === "zip") {
    maskOpts = {
      mask: "99999",
      rightAlign: false,
      placeholder: "",
    };
  }

  // apply mask only if we have valid option
  if (maskOpts) {
    Inputmask(maskOpts).mask(el);
  }
};
