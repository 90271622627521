const initialState = () => ({
  advanceAmount: null,
  minimumPayment: null,
  billingCycle: null,
  paymentDetails: {
    bankAccountType: null,
    routingNumber: null,
    accountNumber: null,
    accountNumberChanged: false,
    routingNumberChanged: false,
  },
  idvResult: null,
});

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    setAdvanceAmountDetails(state, payload) {
      state.advanceAmount = payload.advanceAmount;
      state.minimumPayment = payload.minimumPayment;
      state.billingCycle = payload.billingCycle;
    },
    setPaymentDetails(state, paymentDetails) {
      state.paymentDetails = paymentDetails;
    },
    setIdvResult(state, idvResult) {
      state.idvResult = idvResult;
    },
    resetDrawRequestState(state) {
      Object.assign(state, initialState());
    },
  },
  getters: {
    idvPassed(state) {
      return state.idvResult === "passed";
    },
    idvPending(state) {
      return state.idvResult === "pending";
    },
    idvRejected(state) {
      return state.idvResult === "document_rejected";
    },
  },
};
