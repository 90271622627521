import drawRequest from "@secure/modules/lineOfCredit/drawRequestStore";
import auth from "./authStore";
import loan from "./loan";
import customer from "./customerStore";

export default {
  auth,
  loan,
  customer,
  drawRequest
};
