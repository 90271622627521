<template>
  <button
    :class="{
      'btn-full': buttonFull,
      'btn-min-width': buttonMinWidth,
      'btn-lg': size === 'large',
      'btn-sm': size === 'small',
      'btn-inverse': color === 'inverse',
      'btn-warning': color === 'warning',
      'btn-settlement': color === 'settlement',
      'btn-settlement-inverse': color === 'settlement-inverse',
      'btn-highlight': highlight
    }"
    :disabled="disabled"
    class="btn"
    @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    color: {
      type: String,
      default: "default",
      validator(value) {
        return ["default", "inverse", "warning", "settlement", "settlement-inverse"].indexOf(value) !== -1;
      }
    },
    size: {
      type: String,
      default: "default",
      validator(value) {
        return ["default", "large", "small"].indexOf(value) !== -1;
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonFull: {
      type: Boolean,
      default: false
    },
    buttonMinWidth: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.btn,
button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
  outline: none;
  padding: 0.4em 10px;
  text-transform: none;
  font-size: 16px;
  line-height: 1em;
  display: inline-block;
  text-align: center;
  background-color: #fff;
  color: $button-base-color;
  font-weight: bold;
  border: 1.5px solid $button-base-color;
  border-radius: 4px;
  position: relative;
  text-decoration: none !important;
  -webkit-transition: color 0.3s linear, background 0.3s linear,
    opacity 0.3s linear, border-color 0.3s linear;
  transition: color 0.3s linear, background 0.3s linear, opacity 0.3s linear,
    border-color 0.3s linear;
}

.btn:hover,
button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="file"]:hover,
input[type="submit"]:hover {
  text-decoration: none;
  background-color: $button-base-color;
  color: #fff;
  border-color: $button-base-color;
}

// Button sizing

.btn.btn-sm,
button.btn-sm,
input[type="button"].btn-sm,
input[type="reset"].btn-sm,
input[type="file"].btn-sm,
input[type="submit"].btn-sm {
  font-size: 12px;
  padding: 6px 15px;
  border-width: 1px;
}

.btn.btn-lg,
button.btn-lg,
input[type="button"].btn-lg,
input[type="reset"].btn-lg,
input[type="file"].btn-lg,
input[type="submit"].btn-lg {
  font-size: 18px !important;
  padding: 0.7em 25px;
}

.btn.btn-full,
button.btn-full,
input[type="button"].btn-full,
input[type="reset"].btn-full,
input[type="file"].btn-full,
input[type="submit"].btn-full {
  width: 100%;
}

.btn.btn-min-width,
button.btn-min-width,
input[type="button"].btn-min-width,
input[type="reset"].btn-min-width,
input[type="file"].btn-min-width,
input[type="submit"].btn-min-width {
  min-width: 265px;
  max-width: 265px;
}

.btn.btn-lg.btn-min-width,
button.btn-lg.btn-min-width,
input[type="button"].btn-lg.btn-min-width,
input[type="reset"].btn-lg.btn-min-width,
input[type="file"].btn-lg.btn-min-width,
input[type="submit"].btn-lg.btn-min-width {
  min-width: 300px;
}

// Button Inverse
.btn.btn-inverse,
button.btn-inverse,
input[type="button"].btn-inverse,
input[type="reset"].btn-inverse,
input[type="file"].btn-inverse,
input[type="submit"].btn-inverse {
  background-color: $button-base-color;
  border-color: $button-base-color;
  color: #fff;
}

.btn.btn-inverse:hover,
button.btn-inverse:hover,
input[type="button"].btn-inverse:hover,
input[type="reset"].btn-inverse:hover,
input[type="file"].btn-inverse:hover,
input[type="submit"].btn-inverse:hover {
  background-color: #fff;
  color: $button-base-color;
  border-color: $button-base-color;
}

// Button Warning
.btn.btn-warning,
button.btn-warning,
input[type="button"].btn-warning,
input[type="reset"].btn-warning,
input[type="file"].btn-warning,
input[type="submit"].btn-warning {
  background-color: $button-warning-color;
  border-color: $button-warning-color;
  color: #fff;
}

.btn.btn-warning:hover,
button.btn-warning:hover,
input[type="button"].btn-warning:hover,
input[type="reset"].btn-warning:hover,
input[type="file"].btn-warning:hover,
input[type="submit"].btn-warning:hover {
  color: $button-warning-color;
  background-color: #fff;
  border-color: $button-warning-color;
}

// button:disabled,
button[disabled].btn,
button[disabled].btn:hover,
button[disabled].btn:focus,
button[disabled].btn:active {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

// Button Settlement
.btn.btn-settlement,
button.btn-settlement,
input[type="button"].btn-settlement,
input[type="reset"].btn-settlement,
input[type="file"].btn-settlement,
input[type="submit"].btn-settlement {
  background-color: $button-settlement-color;
  border-color: $button-settlement-color;
  color: #fff;
}

.btn.btn-settlement:hover,
button.btn-settlement:hover,
input[type="button"].btn-settlement:hover,
input[type="reset"].btn-settlement:hover,
input[type="file"].btn-settlement:hover,
input[type="submit"].btn-settlement:hover {
  color: $button-settlement-color;
  background-color: #fff;
  border-color: $button-settlement-color;
}

.btn.btn-settlement-inverse,
button.btn-settlement-inverse,
input[type="button"].btn-settlement-inverse,
input[type="reset"].btn-settlement-inverse,
input[type="file"].btn-settlement-inverse,
input[type="submit"].btn-settlement-inverse {
  color: $button-settlement-color;
  background-color: #fff;
  border-color: $button-settlement-color;
}

.btn.btn-settlement-inverse:hover,
button.btn-settlement-inverse:hover,
input[type="button"].btn-settlement-inverse:hover,
input[type="reset"].btn-settlement-inverse:hover,
input[type="file"].btn-settlement-inverse:hover,
input[type="submit"].btn-settlement-inverse:hover {
  background-color: $button-settlement-color;
  border-color: $button-settlement-color;
  color: #fff;
}

@media (max-width: 1023px) {
  .btn.btn-lg,
  button.btn-lg,
  input[type="button"].btn-lg,
  input[type="reset"].btn-lg,
  input[type="file"].btn-lg,
  input[type="submit"].btn-lg {
    padding: 0.6em 10px;
    font-size: 17px;
  }

  .btn.btn-lg.btn-min-width,
  button.btn-lg.btn-min-width,
  input[type="button"].btn-lg.btn-min-width,
  input[type="reset"].btn-lg.btn-min-width,
  input[type="file"].btn-lg.btn-min-width,
  input[type="submit"].btn-lg.btn-min-width {
    min-width: 200px;
  }

  .btn.btn-letter,
  button.btn-letter,
  input[type="button"].btn-letter,
  input[type="reset"].btn-letter,
  input[type="file"].btn-letter,
  input[type="submit"].btn-letter {
    padding: 0.6em 10px;
    font-size: 17px;
  }
}

.btn-highlight {
  border: 3px solid $button-highlight-color !important;
}

@media (max-width: 767px) {
  .btn.btn-min-width,
  button.btn-min-width,
  input[type="button"].btn-min-width,
  input[type="reset"].btn-min-width,
  input[type="file"].btn-min-width,
  input[type="submit"].btn-min-width {
    min-width: 250px;
    max-width: 250px;
  }
}
</style>
