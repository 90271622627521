<template>
  <div class="my_checkbox">
    <input
      :id="checkBoxId"
      v-model="internalValue"
      type="checkbox"
      :required="required"
      :disabled="disabled"
      @change="change"
    >
    <label :for="checkBoxId">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalValue: this.value,
      checkBoxId: Math.random() * Date.now()
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        // console.log(newVal, oldVal);
        this.internalValue = newVal;
      }
    }
  },
  methods: {
    change() {
      this.$emit("input", this.internalValue);
    }
  }
};
</script>

<style>
.my_checkbox input[type="checkbox"] {
  opacity: 0;
}

.my_checkbox label {
  position: relative;
  display: inline-block;
  font-size: 0.9em;

  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 35px;
}

.my_checkbox label::before,
.my_checkbox label::after {
  position: absolute;
  content: "";

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.my_checkbox label::before {
  height: 24px;
  width: 24px;

  border: 1px solid;
  left: 0px;

  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
  top: 3px;
}

/*Checkmark of the fake checkbox*/
.my_checkbox label::after {
  height: 10px;
  width: 17px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(-45deg);
  left: 4px;
  top: 7px;
}

/*Hide the checkmark by default*/
.my_checkbox input[type="checkbox"] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.my_checkbox input[type="checkbox"]:checked + label::after {
  content: "";
}
</style>
