<template>
  <input
    v-model="resetCode"
    type="text"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    :maxlength="maxLength"
  >
</template>

<script>

export default {
  name: "PasswordResetCodeInput",
  props: {
    mask: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: [String, Number],
      default: ""
    }
  },

  data () {
    return {
      code: ""
    };
  },

  computed: {
    resetCode: {
      get() {
        return this.code;
      },
      set(newVal) {
        if (newVal && newVal.length > 4) {
          if (newVal.charAt(4) !== "-") {
            this.code = `${newVal.slice(0, 4)}-${newVal.slice(4)}`;
            this.$forceUpdate();
          }
        }

        if (newVal && newVal.length >= 9) {
          this.code = newVal.slice(0, 9);
          this.$forceUpdate();
        }

        this.code = this.code.toUpperCase();

        this.$emit("input", this.code);
      }
    }
  },
};
</script>
