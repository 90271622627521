<template>
  <div
    class="footer-links-section"
  >
    <a
      class="footer-disclosures-links footer-term-condition-link"
      :href="settings.link_terms_of_use_href"
      target="_blank"
    >{{ settings.link_terms_of_use_label }}</a>
    <span class="footer-link-divider" />
    <a
      class="footer-disclosures-links footer-private-police-link"
      :href="settings.link_privacy_policy_href"
      target="_blank"
    >{{ settings.link_privacy_policy_label }}</a>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FooterLinks",

  computed: {
    ...mapState("customer", ["settings"]),
  }
};
</script>

<style lang="scss" scoped>
.footer-links-section {
  margin-top: 16px;
  margin-bottom: 16px;
}
.footer-links-section a.footer-disclosures-links{
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
  color: $footer-top-text-color !important;
}

.footer-term-condition-link{
  margin-right: 15px;
}

.footer-private-police-link {
  margin-left: 15px;
}

.footer-link-divider {
  border-right: solid 1px $footer-top-text-color;
}
</style>
