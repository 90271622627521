import Vue from "vue";
import ContentHeader from "@secure/layout/ContentHeader.vue";
import HeadTitle from "@/components/HeadTitle.vue";
import InputGroup from "@/components/ui/InputGroup.vue";
import NumberInput from "@/components/ui/NumberInput.vue";
import Select from "@/components/ui/Select.vue";
import TextInput from "@/components/ui/TextInput.vue";
import PasswordResetCodeInput from "@/components/ui/PasswordResetCodeInput.vue";
import DatePicker from "@/components/ui/DatePicker.vue";
import FormGroup from "@/components/ui/FormGroup.vue";
import Checkbox from "@/components/ui/Checkbox.vue";
import RadioInput from "@/components/ui/RadioInput.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import Accordion from "@/components/ui/Accordion.vue";
import AccordionItem from "@/components/ui/AccordionItem.vue";
import Popup from "@/components/ui/Popup.vue";
import Loader from "@/components/ui/Loader.vue";

Vue.component("InputGroup", InputGroup);
Vue.component("TextInput", TextInput);
Vue.component("NumberInput", NumberInput);
Vue.component("Select", Select);
Vue.component("Checkbox", Checkbox);
Vue.component("RadioInput", RadioInput);
Vue.component("DatePicker", DatePicker);
Vue.component("FormGroup", FormGroup);
Vue.component("BaseButton", BaseButton);
Vue.component("Accordion", Accordion);
Vue.component("AccordionItem", AccordionItem);
Vue.component("Popup", Popup);
Vue.component("ContentHeader", ContentHeader);
Vue.component("HeadTitle", HeadTitle);
Vue.component("Loader", Loader);
Vue.component("PasswordResetCodeInput", PasswordResetCodeInput);
