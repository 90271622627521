<template>
  <header
    id="header"
    :class="{ 'header-reverse': reverseBackground }"
  >
    <div class="header-holder">
      <div class="container-fluid">
        <div class="logo logo-desktop">
          <a :href="url">
            <img
              v-if="reverseBackground"
              :src="$settings_asset(settings.asset_logo_header_desktop_reverse)"
              alt="Logo"
            >
            <img
              v-else
              :src="$settings_asset(settings.asset_logo_header_desktop)"
              alt="Logo"
            >
          </a>
        </div>

        <div class="logo logo-mobile">
          <a :href="url">
            <img
              v-if="reverseBackground"
              :src="$settings_asset(settings.asset_logo_header_mobile_reverse)"
              alt="Logo"
            >
            <img
              v-else
              :src="$settings_asset(settings.asset_logo_header_mobile)"
              alt="Logo"
            >
          </a>
        </div>

        <slot />
      </div>
    </div>
    <FlashMessage
      :position="'right top'"
    />
  </header>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    reverseBackground: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      url: `${process.env.VUE_APP_CUSTOMER_PORTAL_URL}active-loan/dashboard`
    };
  },
  computed: {
    ...mapState("customer", ["settings"])
  }
};
</script>

<style scoped lang="scss">
#header {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: $header-bg-color;

&.header-reverse {
   background-color: $header-reverse-bg-color !important;
 }

&.header-login {
   border-bottom: 1.5px solid $login-header-border-color;
 }

&.header-admin {
   border-bottom: 1.5px solid $header-admin-border-color;
 }

&.header-step .container-fluid {
   display: flex;

& > .logo {
    flex: 1;
  }

& > .step-list {
    flex: none;
    padding-right: 15px;
    font-size: 0;
    color: #fff;

li {
  width: 138px;
  display: inline-block;
  font-size: 15px;
  line-height: 1.2;
}

li.active .step-block {
  background-color: $header-step-color;
}

li:last-child .step-block {
  border-width: 1px 1px 1px 1px;
}

.step-block {
  background-color: #fff;
  width: 100%;
  height: 16px;
  border-width: 1px 0 1px 1px;
  border-color: #dadada;
  border-style: solid;
}

span {
  display: block;
  margin-bottom: 10px;
}

@media (max-width: 1023px) {
  li {
    width: 58px;
    font-size: 12px;
  }
}
}
}

.header-holder {
  padding: 15px 0;
  width: 100%;
}

.container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: relative;
}

@media (max-width: 1023px) {
&.header-login {
  border: none;
}

&.header-admin {
   background-color: $header-bg-color-mobile;
   border-bottom: 1.5px solid $header-admin-border-color-mobile;
 }

  .container-fluid {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
}
</style>
