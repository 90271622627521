<template>
  <div class="not-found">
    <h1 class="not-found__header">
      Authentication Error Occurred
    </h1>
    <p class="not-found__text">
      Unable to authenticate user credentials, please contact customer service for assistance
      <a :href="`mailto:${ settings.company_email }`">{{ settings.company_email }}</a> or
      <a :href="`tel:${settings.company_phone}`">{{ settings.company_phone }}</a>
    </p>
    <a :href="settings.link_main_website_href">
      <img
        :src="$settings_asset(settings.asset_logo_header_mobile)"
        alt="Logo"
      >
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Maintenance",
  computed: {
    ...mapState("customer", ["settings"]),
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background-color: $fullloader-bg-color;
  &__header {
    padding: 10px;;
    font-size: 3em;
    text-align: center;
    font-weight: bold;
  }
  &__text {
    font-size: 30px;
    text-align: center;
    & a {
      color: #fff !important;
    }
  }
}

</style>
