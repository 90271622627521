<template>
  <div id="wrapper-direct-mail-page">
    <environment-banner />
    <AppHeader
      :reverse-background="reverseBackground"
      class="header-login"
    />

    <HeadTitle :title="`Client Login - ${settings.company_long_name}`" />

    <div class="intro-alt">
      <div class="shell">
        <div class="intro__back">
          <picture>
            <source
              media="(max-width:767px)"
              srcset="@secure/assets/images/temp/intro-alt-back-mobile.jpg"
            >

            <img
              src="@secure/assets/images/temp/intro-alt-back.jpg"
              alt="highlights-image"
            >
          </picture>
        </div><!-- /.intro__back -->

        <div class="intro__aside">
          <img
            src="@secure/assets/images/temp/hero-aside.png"
            alt=""
          >
        </div><!-- /.intro__aside -->

        <div class="intro__wrapper">
          <div class="intro__content">
            <h1>An Offer Reserved Just For You!</h1>

            <h2>
              As a valued customer, get your funds faster than ever!
            </h2>
          </div><!-- /.intro__content -->
        </div><!-- /.intro__wrapper -->
      </div><!-- /.shell -->
    </div>

    <div class="container-fluid">
      <div class="row pt-l">
        <div class="col-md-6">
          <div class="row-section pl-xs pr-xs pb-0">
            <h3 class="title pb-xs">
              Account Login
            </h3>
          </div>

          <div class="row-section p-xs account-login-form">
            <div class="row">
              <div class="col-md-12">
                <FormGroup label="Reservation Code">
                  <TextInput
                    v-model="form.reservationCode"
                    type="email"
                    class="form-control form-control-lg"
                    required
                  />
                  <template
                    v-if="formSubmitted"
                    #error
                  >
                    <i v-if="!$v.form.reservationCode.required">Reservation Code is required.</i>
                  </template>
                </FormGroup>
              </div>

              <div class="col-md-12">
                <span
                  v-if="errorMessage"
                  class="help-block with-errors"
                >
                  {{ errorMessage }}
                </span>
              </div>

              <div class="col-md-12 mt-s">
                <FormGroup label="Email">
                  <TextInput
                    v-model="form.username"
                    type="email"
                    class="form-control form-control-lg"
                    required
                  />
                  <template
                    v-if="formSubmitted"
                    #error
                  >
                    <i v-if="!$v.form.username.required">Email is required.</i>
                    <i v-if="!$v.form.username.email">Must be a valid email.</i>
                  </template>
                </FormGroup>
              </div>

              <div class="col-md-12 mt-s">
                <FormGroup label="Password">
                  <InputGroup>
                    <TextInput
                      v-model="form.password"
                      :type="passwordInputType"
                      class="form-control form-control-lg fs-exclude"
                      required
                    />

                    <template #icon>
                      <img
                        src="@secure/assets/images/icon-view.png"
                        @click="togglePasswordInputType"
                      >
                    </template>
                  </InputGroup>
                  <p
                    class="forgot-password-link mt-s"
                    @click="sendRequestModalOpen = true"
                  >
                    Forgot your password?
                  </p>
                  <template
                    v-if="formSubmitted"
                    #error
                  >
                    <i v-if="!$v.form.password.required">Password is required.</i>
                    <i v-if="!$v.form.password.minLength">
                      Password must have {{ $v.form.password.$params.minLength.min }} characters.
                    </i>
                  </template>
                </FormGroup>
              </div>

              <div
                v-if="settings.show_login_privacy_and_term_text"
                class="col-md-12 mt-s"
              >
                By signing in to the Client Portal, you agree to our
                <a
                  :href="settings.link_terms_of_use_href"
                  target="_blank"
                > Terms of Use </a>
                and
                <a
                  :href="settings.link_privacy_policy_href"
                  target="_blank"
                > Privacy Policy </a>.
              </div>
            </div>
          </div>

          <div class="row account-login-actions">
            <div class="col-md-7 mt-s">
              <FormGroup>
                <Recaptcha
                  @verifyRecaptcha="onVerifyRecaptcha"
                  @recaptchaExpired="onRecaptchaExpired"
                />
                <template
                  v-if="formSubmitted"
                  #error
                >
                  <i v-if="!$v.recaptchaVerified.required">Recaptcha is required.</i>
                </template>
              </FormGroup>
            </div>

            <div
              v-if="loading"
              class="col-md-5 loader-container"
            >
              <Loader />
            </div>
            <span v-else>
              <div class="col-md-5 mt-s">
                <BaseButton
                  :disabled="!recaptchaVerified"
                  button-full
                  size="large"
                  color="inverse"
                  @click="handleLogin"
                >
                  SIGN IN
                </BaseButton>
              </div>
            </span>
          </div>
        </div>
        <div class="col-md-6 easy-step-process">
          <div class="row-section pl-xs pr-xs pb-0">
            <h3 class="title pb-xs">
              Easy 3-Step process
            </h3>
          </div>
          <div class="row-section pl-xs pr-xs pb-0">
            <ol class="list-counter">
              <li>
                <span />
                <p>Enter your reservation code and log into your account.</p>
              </li>
              <li>
                <span />
                <p>Confirm your information is current and review your new loan offer.</p>
              </li>
              <li>
                <span />
                <p>Receive your finds as soon as today!</p>
              </li>
            </ol>
          </div>
          <div class="row-section section-info pl-xs pr-xs pb-0 mt-m">
            <p>
              Questions? Give us a call at 888.354.1532, Monday-Friday,
              8am-6pm (CT) or email {{ settings.company_email }}
            </p>
            <p>
              *Loan applications fully verified and approved before 11am (CT) will be funded as soon as the same day.
              Loan applications fully verified and approved after 11am (CT) will be funded as soon as the next
              business day. {{ brandSettings.what_is_product_name }} is not responsible for any additional charges
              applied by your financial institution.
            </p>
          </div>
        </div>
      </div>
    </div>

    <section class="customer-reviews">
      <div class="section__logos">
        <div class="section__logo">
          <!-- TrustBox widget - Carousel -->
          <div
            ref="trustbox"
            class="trustpilot-widget"
            data-locale="en-US"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="634eef41dd60fc1a7d6032f8"
            data-style-height="140px"
            data-style-width="100%"
            data-theme="light"
            data-tags="bob_l"
            data-review-languages="en"
          >
            <a
              href="https://www.trustpilot.com/review/atlaspersonalfinance.com"
              target="_blank"
              rel="noopener"
            >Trustpilot</a>
          </div>
          <!-- End TrustBox widget -->
        </div><!-- /.section__logo -->
      </div><!-- /.section__logos -->
    </section>

    <div class="container-fluid">
      <div class="row pt-m pb-l">
        <div class="col-md-6">
          <div class="row-section pl-xs pr-xs pb-0">
            <h3 class="title pb-xs">
              As a valued {{ settings.company_name }} customer you can:
            </h3>
          </div>
          <div class="row-section pl-xs pr-xs pb-0">
            <ol class="list-counter">
              <li>
                <span />
                <p>Enjoy reduced interest rates.</p>
              </li>
              <li>
                <span />
                <p>Keep your data safe and secure.</p>
              </li>
              <li>
                <span />
                <p>Apply in minutes without re-filling out an application</p>
              </li>
            </ol>
          </div>
        </div>
        <div class="col-md-6">
          <div class="section secure-logos">
            <div class="shell">
              <div class="three-columns grid grid--template-2 grid--top-aligned">
                <div class="section__col grid-item">
                  <img
                    src="@secure/assets/images/icons/atlas-badges_SOC-II.png"
                    alt="256 BIT Encryption"
                    class="safe-secure-icon-img"
                  >
                </div>
                <div class="section__col grid-item even">
                  <img
                    src="@secure/assets/images/icons/atlas-badges_PII-Safe.png"
                    alt="256 BIT Encryption"
                    class="safe-secure-icon-img"
                  >
                </div>
                <div class="section__col grid-item">
                  <img
                    src="@secure/assets/images/icons/atlas-badges_Daily-Site-Testing.png"
                    alt="256 BIT Encryption"
                    class="safe-secure-icon-img"
                  >
                </div>
              </div>
            </div>
            <div class="shell mt-m">
              <div class="two-columns grid grid--template-2 grid--top-aligned">
                <div class="section__col grid-item">
                  <img
                    src="@secure/assets/images/icons/atlas-badges_256-BIT.png"
                    alt="256 BIT Encryption"
                    class="safe-secure-icon-img"
                  >
                </div>
                <div class="section__col grid-item even">
                  <img
                    src="@secure/assets/images/icons/atlas-badges_MFA-2FA.png"
                    alt="256 BIT Encryption"
                    class="safe-secure-icon-img"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LoginSendResetRequestModal
      :open="sendRequestModalOpen"
      @close="sendRequestModalOpen = false"
    />

    <LoginResetPasswordModal
      v-if="resetPasswordUrl"
      :open="resetPasswordModalOpen"
      :url="resetPasswordUrl"
      @close="resetPasswordModalOpen = false"
    />

    <TemporarilyBlockedModal
      :message="blockedMessage"
      @reset-block-message="resetBlockMessage"
    />

    <VerificationModal
      :open="verificationModalOpen"
      :verification-methods="verificationMethods"
      @close="onCloseVerificationModal"
      @valid-code="onValidCode"
      @invalid-code="onInvalidCode"
    />
    <AppFooter />
  </div>
</template>

<script>
/* eslint global-require: 0, no-param-reassign: 0, import/no-dynamic-require: 0 */
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import loadashGet from "lodash/get";
import LoginSendResetRequestModal from "@secure/pages/Login/LoginSendResetRequestModal.vue";
import LoginResetPasswordModal from "@secure/pages/Login/LoginResetPasswordModal.vue";
import VerificationModal from "@secure/components/VerificationModal.vue";
import Bugsnag from "@bugsnag/js";
import moduleLoader from "@secure/moduleLoader";
import AppHeader from "@secure/layout/AppHeader.vue";
import AppFooter from "@secure/layout/AppFooter.vue";
import Recaptcha from "@/components/Recaptcha.vue";
import TemporarilyBlockedModal from "@secure/pages/Login/TemporarilyBlockedModal.vue";

export default {
  name: "DirectMailLogin",
  components: {
    TemporarilyBlockedModal,
    Recaptcha,
    LoginSendResetRequestModal,
    LoginResetPasswordModal,
    VerificationModal,
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        reservationCode: "",
      },
      formSubmitted: false,
      showIphoneHelpPopup: false,
      passwordInputType: "password",
      recaptchaVerified: null,
      errorMessage: null,
      loading: false,
      sendRequestModalOpen: false,
      resetPasswordModalOpen: false,
      resetPasswordUrl: null,
      verificationModalOpen: false,
      verificationMethods: [],
      blockedMessage: "",
    };
  },

  computed: {
    ...mapState("customer", ["leadInfo", "settings", "brandSettings"]),
    ...mapState("auth", ["version"]),
    ...mapGetters("customer", ["isOrrickBrand"]),

    reverseBackground() {
      return !this.isOrrickBrand;
    }
  },

  created() {
    const query = window.location.search;
    if (query && query.startsWith("?url=")) {
      const uri = query.substring(5);
      this.resetPasswordModalOpen = true;
      this.resetPasswordUrl = decodeURIComponent(uri);
    }
  },

  async mounted() {
    await this.$axios.post("api/v1/customer-login-metric/track-page-view");

    const trustBox = this.$refs.trustbox;

    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustBox);
    }
  },

  methods: {
    ...mapActions("customer", ["sendVerificationCode", "setLeadInfo", "storeDirectMailLogin"]),
    ...mapActions("auth", ["handleLoginSuccess"]),

    togglePasswordInputType() {
      this.passwordInputType = this.passwordInputType === "password" ? "text" : "password";
    },

    resetBlockMessage() {
      this.blockedMessage = "";
    },

    handleLogin() {
      this.formSubmitted = true;
      this.errorMessage = null;

      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.loading = true;

        this.$axios
          .get("sanctum/csrf-cookie")
          .then(() => this.$axios.post("customer/login", this.form))
          .then(async (response) => {
            if (response.status === 200) {
              if (response.data && response.data.identityTheft === true) {
                this.$router.push("/identity-theft");
              } else {
                this.setLeadInfo(loadashGet(response, "data.data"));

                this.$store.commit("setProduct", response.data.data);

                this.pushGtmDataLayer();

                let hasPhoneMethod = false;
                let verificationEmail = "";
                this.verificationMethods = response.data.data.verificationMethods.map((method) => {
                  if (method.type === "phone") {
                    hasPhoneMethod = true;
                  }
                  // we can determine email like this since there is only one email verificationOption
                  if (method.type === "email") {
                    verificationEmail = method.value;
                  }
                  const methodTitle = this.sanitizeMethod(method);
                  return {
                    title: methodTitle,
                    ...method,
                  };
                });
                this.verificationModalOpen = true;

                // send verification via email if there are no phone options which could also mean sms is disabled
                // before SMS 2FA our verification code was sent here with no catch, leaving for now
                if (!hasPhoneMethod) {
                  this.sendVerificationCode(verificationEmail);
                }
              }
            }
          })
          .then(async () => {
            await moduleLoader.registerProductModule(this.$store, this.$router);
          })
          .catch((error) => {
            let message = "Error, something went wrong.";

            if (error.response && (error.response.status === 401 || error.response.status === 409)) {
              message = error.response.data.message;
              this.loading = false;
              this.formSubmitted = false;
              this.form.password = "";
            } else {
              if (error.response && (error.response.status === 500 && error.response.data.attempts_message)) {
                this.blockedMessage = error.response.data.attempts_message;
                this.loading = false;
                this.formSubmitted = false;
                this.form.password = "";
              }

              Bugsnag.notify(error);
            }

            this.errorMessage = message;
          });
      }
    },

    sanitizeMethod(method) {
      if (method.type === "phone") {
        return `***-***-**${method.value.slice(-2)}`;
      }
      if (method.type === "email") {
        const lastSix = method.value.slice(-6);
        const first = method.value.charAt(0);
        const replaceCharString = method.value.substr(1, method.value.length - 7).replace(/[^@]/g, "*");

        return `${first}${replaceCharString}${lastSix}`;
      }
    },

    onCloseVerificationModal() {
      this.loading = false;
      this.verificationModalOpen = false;
    },

    async onValidCode() {
      this.loading = false;
      await this.handleLoginSuccess(this.$axios);

      // store direct mail customer login
      await this.storeDirectMailLogin({
        reservationCode: this.form.reservationCode,
        customerId: this.leadInfo?.customer_id,
        email: this.form.username,
      });

      this.$router.push({ name: "active-loan-dashboard" });
    },

    async onInvalidCode() {
      await this.$axios.post("api/v1/customer-login-metric/track-incorrect-2fa-code");
    },

    onVerifyRecaptcha(token) {
      // backend returns boolean after checking this token against the secret key
      if (token) {
        this.$axios
          .get(`customer/recaptcha-verify/${token}`)
          .then((response) => {
            this.recaptchaVerified = response.data;
          })
          .catch(() => {
            this.recaptchaVerified = null;
          });
      }
    },

    onRecaptchaExpired() {
      this.recaptchaVerified = null;
    },

    pushGtmDataLayer() {
      if (this.$gtm.enabled()) {
        // GTM dataLayer.push lead data
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          lead_guid: this.leadInfo.lead_guid,
          lead_id: this.leadInfo.lead_id,
          email: this.leadInfo.email,
          cust_id: this.leadInfo.guid,
        });
      }
      // Explicit FS.identify call if we have it in the window namespace
      const clearIntervalId = setInterval(() => {
        if (window.FS && window.FS.identify) {
          // eslint-disable-next-line no-console
          console.info("Login -> pushGtmDataLayer -> Calling FS.identify");
          window.FS.identify(this.leadInfo.lead_id, {
            email: this.leadInfo.email,
          });
          clearTimeout(clearIntervalId);
        }
      }, 3);
    },
  },
  validations: {
    form: {
      username: { required, email },
      reservationCode: { required },
      password: {
        required,
        minLength: minLength(10),
      },
    },
    recaptchaVerified: { required },
  },
};
</script>

<style lang="scss" scoped>
.with-errors {
  color: #FF0000FF;
}

.title {
  font-weight: 500;
  color: $title-color;
  font-size: 2.2em;
}

.loader-container {
  height: 60px;
  margin-top: 24px;
}

.text-advices {
  color: $title-color;
}

.intro-alt {
  position: relative;
  color: #fff;
  padding: 4rem 0 .4rem;
  overflow: hidden;

  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    padding: 0;
    color: #2A3241;
  }

  .intro__aside {
    position: absolute;
    top: 0;
    right: 0;
    width: 56%;
    height: auto;
    text-align: right;
    opacity: .5;
    mix-blend-mode: multiply;

    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      width: 75%;
    }
  }

  .intro__aside img {
    max-width: 100%;
    height: auto;
  }

  .intro__back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      position: relative;
      padding-bottom: 58%;
      width: calc(95% + 3rem);
      margin: 0 -1.5rem;
    }
  }

  .intro__back img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .intro__wrapper {
    position: relative;
    z-index: 5;
    width: 42%;

    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      width: 100%;
      padding: 2.5rem 0 1rem;
    }
  }

  .intro__content {
    text-shadow: 0 3px 6px rgba(#000, .4);
    margin-bottom: 3rem;

    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      text-shadow: none;
      margin-bottom: 0;
    }
  }

  .intro__content h1 {
    line-height: 1.25;
    margin-bottom: 1.9rem;
    font-weight: 500;

    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      line-height: 1.35;
      margin-bottom: 2.2rem;
    }
  }

  .intro__content h2 {
    line-height: 1.3;
    font-size: 2.0rem;
    font-weight: 300;

    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      max-width: 85%;
    }
  }

  .intro__content h2 sup {
    font-size: 2.3rem;

    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      font-size: 1.7rem;
    }
  }

  .intro__info {
    font-size: 1.2rem;
    line-height: 1.50;
    color: rgba(#fff, .5);
    letter-spacing: -0.005em;

    @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
      display: none;
    }
  }
}

.shell {
  max-width: 1140px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: auto;
  width: 100%;

  @media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.row-section {
  border-bottom: none;
}

.account-login-form {
  background: aliceblue;
  padding: 2rem !important;
  padding-bottom: 0 !important;

  .forgot-password-link {
    text-align: right;
    text-decoration: underline
  }
}

.form-group span.title__label {
  color: #58595B;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}

.list-counter {
  list-style-type: none;
  counter-reset: list-counter;
  padding-left: 0rem;

  li {
    counter-increment: list-counter;
    position: relative;
    padding-left: 2.4rem;
    margin-bottom: 1.6rem;

    span {
      position: absolute;
      //top: 0.4rem;
      left: 0;
      font-size: 1.2rem;
      line-height: 1.58;
      font-weight: 500;
      color: #fff;
      background-color: #5AAB5A;
      border-radius: 50%;
      width: 1.8rem;
      height: 1.8rem;
      display: block;
      text-align: center;
    }

    span:before {
      content: counter(list-counter);
    }

    p {
      font-size: 1.0rem;
    }
  }
}

.title {
  margin-bottom: 0.9rem;
  color: #597FAB;
  font-size: 1.7rem;
  line-height: 0.94;
}

.section-info p {
  font-size: 0.85rem;
}

.customer-reviews {
  background-color: #F7FAFC;
  padding: 2.5rem 0 2.2rem;
  margin: 3rem 0;

  .section__logos {
    display: grid;
    grid-template: 1fr/repeat(3, auto);
    align-items: center;
    justify-content: center;
    grid-gap: 4.4rem;
  }
}

.secure-logos {
  padding-top: 3.5rem;

  .three-columns {
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    display: grid;
  }

  .two-columns {
    grid-template-columns: 1fr 1fr;
    text-align: center;
    display: grid;
  }
}

@media (max-width: 767px), screen and (max-width: 812px) and (orientation: landscape) {

  .customer-reviews .section__logos {
    grid-template: none;
  }

  .secure-logos {
    .section__cols {
      margin-top: 0rem !important;
      display: grid;
      grid-template-columns: auto auto auto;
    }
  }

  .account-login-actions {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .easy-step-process {
    margin-top: 3rem;
  }
}
</style>
