<template>
  <div>
    <CommonHeader
      v-if="!isOrrickBrand"
      :reverse-background="reverseBackground"
    >
      <!-- Forward slots to component -->
      <template
        v-for="(_, slot) in $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="{ ...scope }"
        />
      </template>
    </CommonHeader>
    <OrrickHeader
      v-else
      :reverse-background="reverseBackground"
    >
      <!-- Forward slots to component -->
      <template
        v-for="(_, slot) in $slots"
        #[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="{ ...scope }"
        />
      </template>
    </OrrickHeader>
  </div>
</template>

<script>
import CommonHeader from "@secure/layout/Header/CommonHeader.vue";
import { mapGetters } from "vuex";
import OrrickHeader from "@secure/layout/Header/OrrickHeader.vue";

export default {
  name: "AppHeader",

  components: { OrrickHeader, CommonHeader },

  props: {
    reverseBackground: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapGetters("customer", ["isOrrickBrand"])
  }
};
</script>

<style lang="scss">

</style>
