import { DateTime } from "luxon-business-days";

const filters = {
  // format amount to us format
  currency(initalVal) {
    let parsedValue = parseFloat(initalVal);

    if (Number.isNaN(parsedValue)) {
      parsedValue = 0;
    }

    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    });

    return formatter.format(parsedValue);
  },

  // format date to us format MM/DD/YYYY by default you can overide it
  formatdate(dateStr, format = "MM/dd/yyyy") {
    const parsedDate = DateTime.fromSQL(dateStr);

    if (parsedDate.isValid) {
      return parsedDate.toFormat(format);
    }
  }
};

export default {
  filters,

  install(Vue) {
    // install all filters
    Object.keys(filters).forEach(name => Vue.filter(name, filters[name]));
  }
};
