export const PaymentsStatus = {
  NONE: "payment.status.none",
  PENDING: "payment.status.pending",
  PENDINGREFUND: "payment.status.pendingRefund",
  REFUNDED: "payment.status.refunded",
  SETTLED: "payment.status.settled",
  SUCCESS: "payment.status.success",
  VOIDDECLINED: "payment.status.voiddeClined",
  VOIDED: "payment.status.voided"
};

export const PaymentTypes = {
  SETTLEMENT_PORTAL: 18,
  COVID: 19,
  PAYOFF: 13,
  IPF: 8,
  PRINCIPAL: 2,
  SCHEDULED: 0,
};

export const PaymentMethods = {
  CREDIT: 3,
  CHECKING: 4
};

export const PaymentAccountTypes = {
  CREDIT: "paymentAccount.type.credit",
  CHECKING: "paymentAccount.type.checking"
};

export const AutopayStatus = {
  CANCELLED: "autopay.status.cancelled",
  COMPLETED: "autopay.status.completed",
  FAILED: "autopay.status.failed",
  PENDING: "autopay.status.pending"
};

export const AutopayTypes = {
  RECURRING: "autopay.type.recurring",
  RECURRINGMATCH: "autopay.type.recurringMatch",
  SINGLE: "autopay.type.single"
};

export const AutopayFrequencies = {
  BIWEEKLY: "autopay.recurringFrequency.biWeekly",
  MONTHLY: "autopay.recurringFrequency.monthly",
  SEMIMONTHLY: "autopay.recurringFrequency.semiMonthly",
  SINGLE: "autopay.recurringFrequency.single",
  WEEKLY: "autopay.recurringFrequency.weekly"
};

export const AutopayCancelReturnCodes = [
  "R02",
  "R03",
  "R04",
  "R07",
  "R08",
  "R10",
  "R20",
  "R29",
];

export const TransactionTypes = {
  PAYMENT: "payment",
  SCHEDULED_PAYMENT: "scheduledPayment"
};

export const LoanFrequencies = {
  BIWEEKLY: "loan.frequency.biWeekly",
  MONTHLY: "loan.frequency.monthly",
  SEMIMONTHLY: "loan.frequency.semiMonthly",
  WEEKLY: "loan.frequency.weekly"
};
