<template>
  <div class="form-group">
    <div
      v-if="label"
      class="title"
    >
      <span class="title__label">{{ label }}</span>
    </div>
    <div :class="inputGroupClass">
      <slot />
    </div>
    <div class="help-block with-errors">
      <slot name="error" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormGroup",
  props: {
    label: {
      type: String,
      default: ""
    },
    inputGroupClass: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 7px;

  &__label {
    color: $title-color;
    display: inline-block;
    font-size: 23px;
    line-height: 1.2;
    font-weight: 400;
  }
}

.help-block {
  display: block;
  font-size: 0.9em;
  font-style: italic;
  font-weight: 400;
  margin: 3px 0 0;

  &.with-errors {
    color: #d74e38;
  }
}
</style>
