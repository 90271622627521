<template>
  <div
    class="content-header"
    :class="{ 'p-s': hasContentBody }"
  >
    <HeadTitle :title="`${title} - ${settings.company_long_name}`" />

    <div :class="contentHeaderClasses">
      <div class="mb-xs">
        <p
          class="mb-xxxs"
          style="text-transform: uppercase;"
        >
          {{ fullName }} - {{ displayId }}
        </p>
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div
      v-if="hasContentBody"
      class="mb-xs"
    >
      <slot name="contentBody" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    mobileOnly: {
      type: Boolean,
      default: true,
    },
    contentBodyBottomBorder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("customer", ["fullName"]),
    ...mapState("customer", ["settings"]),
    ...mapGetters("loan", ["displayId"]),
    hasContentBody() {
      return !!this.$slots.contentBody;
    },
    contentHeaderClasses() {
      let classes = "content-header";
      if (this.mobileOnly) {
        classes = `${classes} hidden-ls`;
      }
      if (this.hasContentBody && this.contentBodyBottomBorder) {
        classes = `${classes} content-body`;
      } else {
        classes = `${classes} p-xs`;
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-header {
  background: $content-header-bg-color;
  color: $content-header-text-color;
  padding-bottom: 1px !important;

  & a {
    text-decoration: underline;
    color: $content-header-text-color !important;
  }
}

.content-body {
  border-bottom: 2px solid #e6e7e8;
  margin-bottom: 15px;
}
</style>
