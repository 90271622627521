<template>
  <div class="accordion">
    <div
      class="accordion-head"
      :class="{ active: visible }"
      @click="open"
    >
      <!-- This slot will handle the title/header of the accordion and is the part you click on -->
      <slot name="accordion-head" />
      <i :class="$props.toggleIcon" />
    </div>

    <SlideUpDown
      :active="visible"
      :duration="300"
    >
      <div class="accordion-body">
        <!-- This slot will handle all the content that is passed to the accordion -->
        <slot name="accordion-body" />
      </div>
    </SlideUpDown>
  </div>
</template>

<script>
import SlideUpDown from "./SlideUpDown.vue";

export default {
  name: "AccordionItem",
  components: {
    SlideUpDown
  },
  inject: ["Accordion"],
  props: {
    toggleIcon: {
      type: String,
      default: "arrow"
    },
    // allows accordion to be initially open
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      index: null,
      isActive: this.active,
    };
  },
  computed: {
    visible() {
      return this.isActive || this.index === this.Accordion.activeIndex;
    }
  },
  created() {
    this.index = this.Accordion.count++;
  },
  methods: {
    open() {
      if (this.disabled) {
        return;
      }

      if (this.visible) {
        this.Accordion.activeIndex = null;
        this.isActive = false;
      } else {
        this.Accordion.activeIndex = this.index;
      }
    },
    start(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = "";
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-head {
  background-color: #fff;
  color: $title-color;
  cursor: pointer;
  font-size: 1.1em;
  margin: 0 0 1px 0;
  padding: 10px 5%;
  border-bottom: 2px solid #e6e7e8;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.accordion-head.active {
  border-bottom: 0;
  color: #000;
  font-weight: bold;
}

.accordion-body {
  background: #fff;
  padding: 0px 5%;
  padding-bottom: 40px;
  border-bottom: 2px solid #e6e7e8;
}

.accordion:last-child .accordion-body {
  border-bottom: 0;
}

.accordion-head .plusminus {
  box-sizing: border-box;
  transition: transform 0.3s;
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
  position: relative;
  margin-left: 12px;
}

.accordion-head .plusminus::after {
  content: "";
  display: block;
  width: 24px;
  height: 0px;
  border-bottom: solid 2px $accordion-head-plusminus-color;
  position: absolute;
  bottom: 11px;
}

.accordion-head .plusminus::before {
  content: "";
  display: block;
  width: 24px;
  height: 0px;
  border-bottom: solid 2px $accordion-head-plusminus-color;
  position: absolute;
  bottom: 11px;
  transform: rotate(90deg);
  transition: width 0.3s;
}

.accordion-head.active .plusminus {
  transform: rotate(0deg);
}

.accordion-head.active .plusminus::before {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  border-bottom: solid 2px #a1a1a1;
  position: absolute;
  bottom: 11px;
  transform: rotate(90deg);
}

.accordion-head .arrow {
  width: 15px;
  height: 15px;
  border-top: 2px solid $hyperlink-color;
  border-right: 2px solid $hyperlink-color;
  transform: rotate(45deg);
}

.accordion-head.active .arrow {
  transform: rotate(135deg);
  border-top: 2px solid $hyperlink-color;
  border-right: 2px solid $hyperlink-color;
}
</style>
