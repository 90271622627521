<template>
  <Popup
    :show="open"
    @close="close"
  >
    <template #header>
      <div class="mb-xs">
        <h1>Verification Code</h1>
      </div>
    </template>

    <div
      v-if="selectMethod"
      class="row"
    >
      <div class="col-sm-12">
        Please select where you would like to receive your 4-digit Verification code:
      </div>
      <div class="col-sm-12" />
      <div class="col-sm-12">
        <Select
          :value="method"
          :options="verificationMethods"
          track-by="value"
          label="title"
          required
          @input="updateMethod($event)"
        />
      </div>
      <div
        v-if="selectedMethod && selectedMethod.type === 'phone' && selectedMethodIsDisabled"
        class="col-sm-12"
      >
        We are unable to send SMS to this number
      </div>
      <div
        v-if="errorMessage"
        class="col-sm-12"
      >
        <span class="help-block with-errors">
          {{ errorMessage }}
        </span>
      </div>
      <div class="col-sm-12">
        <Loader
          v-if="checkingVerification || showAsLoading || loading"
          style="height:70px"
        />
        <BaseButton
          v-else
          class="mt-s"
          button-full
          size="large"
          color="inverse"
          :disabled="selectedMethodIsDisabled"
          @click="callSendVerificationCode()"
        >
          Send Code
        </BaseButton>
      </div>
    </div>

    <div
      v-else
      class="row"
    >
      <div
        v-if="hasPhoneMethod"
        class="col-sm-12"
      >
        We have sent an
        {{ selectedMethod.type === 'phone' ? 'SMS message' : 'email' }}
        with a confirmation code to
        {{ selectedMethod.title }}.
        This may take several minutes to arrive.
        <b v-if="selectedMethod.type === 'email'">
          If you don’t see it arrive, please check your SPAM folder.
        </b>
      </div>
      <div
        v-else
        class="col-sm-12"
      >
        We have sent an email with a confirmation code to your email address. This may take several minutes to arrive.
        <b>If you don’t see it arrive, please check your SPAM folder.</b>
      </div>
      <div class="col-sm-12 mt-s">
        <FormGroup>
          <NumberInput
            v-model.lazy="verificationCode"
            class="form-control form-control-lg"
            placeholder="Enter verification code..."
            required
          />
          <template
            v-if="formSubmitted"
            #error
          >
            <i v-if="!$v.verificationCode.required">Verification code is required.</i>
          </template>
        </FormGroup>
      </div>
      <div
        v-if="errorMessage"
        class="col-md-12"
      >
        <span class="help-block with-errors">
          {{ errorMessage }}
        </span>
      </div>
      <div class="col-md-12">
        <Loader
          v-if="checkingVerification || showAsLoading || loading"
          style="height:70px"
        />
        <BaseButton
          v-else
          class="mt-s"
          button-full
          size="large"
          color="inverse"
          @click="save()"
        >
          Submit
        </BaseButton>
      </div>
    </div>
  </Popup>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import Bugsnag from "@bugsnag/js";

export default {
  name: "VerificationModal",
  props: {
    open: {
      type: Boolean,
      default: false
    },
    showAsLoading: {
      type: Boolean,
      default: false
    },
    verificationMethods: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      verificationCode: null,
      formSubmitted: false,
      errorMessage: null,
      checkingVerification: false,
      method: "",
      selectMethod: true,
      loading: false
    };
  },
  validations: {
    verificationCode: { required }
  },
  computed: {
    selectedMethod() {
      return this.verificationMethods.find(x => x.value === this.method);
    },
    hasPhoneMethod() {
      return this.verificationMethods.findIndex(x => x.type === "phone") >= 0;
    },
    selectedMethodIsDisabled() {
      return this.method === "" || (this.selectedMethod.type === "phone" && this.selectedMethod.verified === 0);
    }
  },
  watch: {
    open: {
      immediate: true,
      handler() {
        // if VerificationModal is freshly opened and there is no phone method
        // skip selectMethod and ask for code immediately
        if (this.open && !this.hasPhoneMethod) {
          this.selectMethod = false;
        }
      }
    }
  },
  methods: {
    ...mapActions("customer", ["checkVerificationCode"]),
    ...mapActions("customer", ["sendVerificationCode"]),
    close() {
      if (this.open === true) {
        this.$emit("close");
        this.verificationCode = null;
        this.formSubmitted = false;
        this.errorMessage = null;
        this.selectMethod = true;
      }
    },
    async callSendVerificationCode() {
      this.loading = true;
      try {
        await this.sendVerificationCode(this.method);

        this.selectMethod = false;
        this.loading = false;
      } catch (err) {
        Bugsnag.notify(err);
        this.errorMessage = "We were unable to send a Verification Code to that method. Please try a different method.";
        this.method = "";
        this.loading = false;
      }
    },
    async save() {
      this.errorMessage = null;
      this.formSubmitted = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.checkingVerification = true;

        const result = await this.checkVerificationCode(this.verificationCode);

        if (result.verified) {
          this.$emit("valid-code");
        } else {
          this.errorMessage = result.message;
          this.$emit("invalid-code");
        }

        this.checkingVerification = false;
      }
    },
    updateMethod(event) {
      this.method = event.value;
      this.errorMessage = "";
    }
  }
};
</script>

<style scoped>
.with-errors {
  color: red;
}
</style>
