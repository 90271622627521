import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@secure/pages/Login/Login.vue";
import DirectMailLogin from "@secure/pages/Login/DirectMailLogin.vue";
import Maintenance from "@secure/pages/Maintenance.vue";
import NotFound from "@secure/pages/NotFound.vue";
import IdentityTheft from "@secure/pages/IdentityTheft.vue";
import axios from "axios";
import MainNonAuthenticated from "@secure/layout/MainNonAuthenticated.vue";
import dataStore from "@secure/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      component: MainNonAuthenticated,
      children: [
        {
          path: "",
          name: "login",
          component: Login
        }
      ]
    },
    {
      path: "/direct-mail",
      name: "directMailLogin",
      component: DirectMailLogin,
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: async (to, from, next) => {
        await dataStore.commit("auth/AUTH_LOGOUT");
        await dataStore.commit("setProduct");
        await next({ name: "login" });
        axios.defaults.withCredentials = true;
        await axios.post(`${process.env.VUE_APP_UNDERWRITING_URL}customer/logout`);
      }
    },
    {
      path: "/404",
      name: "not-found",
      component: NotFound
    },
    {
      path: "/maintenance",
      name: "maintenance",
      component: Maintenance
    },
    {
      path: "/identity-theft",
      name: "identity-theft",
      component: IdentityTheft
    },
    {
      path: "*",
      redirect: "/404"
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  // console.log(to, from);
  const { isLoggedIn, maintenance } = dataStore.state.auth;

  if (maintenance && to.name !== "maintenance") {
    next({ name: "maintenance" });
    return;
  }

  // if loan is sold off, only allow the following routes
  const allowedSoldOffRoutes = [
    "active-loan-dashboard",
    "help-center",
    "active-loan-loan-agreements",
    "logout",
    "testing"
  ];

  if (dataStore.getters["loan/isLoanSoldOff"] && isLoggedIn && !allowedSoldOffRoutes.includes(to.name)) {
    next({ name: "active-loan-dashboard" });
    return;
  }

  // if loan is rescinded, only allow the following routes
  const allowedRescindedRoutes = ["active-loan-dashboard", "help-center", "logout", "testing"];
  if (dataStore.getters["loan/isLoanRescinded"] && isLoggedIn && !allowedRescindedRoutes.includes(to.name)) {
    next({ name: "active-loan-dashboard" });
    return;
  }

  next();
});

export default router;
