<template>
  <div id="wrapper">
    <environment-banner />
    <AppHeader
      :reverse-background="reverseBackground"
      class="header-login"
    />

    <div class="login-block">
      <div class="container-fluid">
        <div class="container-holder">
          <div class="box-status">
            <router-view />
          </div>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import AppHeader from "./AppHeader.vue";
import AppFooter from "./AppFooter.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
  },
  computed: {
    ...mapState("customer", ["settings"]),
    ...mapGetters("customer", ["isOrrickBrand"]),
    reverseBackground() {
      return !this.isOrrickBrand;
    }
  },
};
</script>

<style lang="scss">
.login-block {
  background-color: $login-page-bgcolor;
  padding: 20px;

  .box-status {
    border-radius: unset;
    max-width: 550px;
    padding: 15px 5px;
  }

  @media (max-width: 1023px) {
    & {
      padding: 20px;
    }

    & .container-holder {
      padding: 30px;
    }
  }

  @media (max-width: 566px) {
    & {
      padding: 15px;
      background-color:  $login-page-bgcolor-on-566px;
    }

    & .container-holder {
      padding: 0px;
    }
  }
}
</style>
