<template>
  <div>
    <Popup
      :show="open"
      @close="close"
    >
      <template #header>
        <div class="mb-xs">
          <h1>Forgot Password</h1>
        </div>
      </template>

      <form>
        <div class="row">
          <div class="col-sm-12">
            <p>
              Please enter the email associated with your loan,
              and we will send you a code that you can use to reset your password.
              <b>If you don’t see it arrive, please check your SPAM folder.</b>
            </p>
            <FormGroup>
              <TextInput
                v-model.trim="email"
                type="email"
                placeholder="Enter Email..."
                class="form-control form-control-lg"
                required
              />
              <template
                v-if="submitted"
                #error
              >
                <i v-if="!$v.email.email">Must be a valid email address.</i>
                <i v-if="!$v.email.required">Email is required.</i>
              </template>
            </FormGroup>
          </div>
        </div>
      </form>

      <template #footer>
        <div class="row">
          <div class="col-md-12">
            <div v-if="message">
              <b :style="{ color: isError ? '#d74e38' : null }">{{ message }}</b>
            </div>
            <BaseButton
              v-else
              class="mt-s"
              button-full
              size="large"
              color="inverse"
              @click="submit"
            >
              Submit
            </BaseButton>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "LoginSendResetRequestModal",

  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      email: "",
      message: null,
      submitted: false,
      isError: false
    };
  },

  methods: {
    submit() {
      this.submitted = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        // eslint-disable-next-line max-len
        this.message = "Email has been sent! Please click the link in the email to return to this page and reset your password.";

        this.$axios
          .get(`api/v1/customer/get-signed-password-reset-url/${this.email}`)
          .then(response => {
            if (response.status !== 200) {
              this.errorMessage();
            }
          })
          .catch(() => {
            this.errorMessage();
          });
      }
    },

    close() {
      this.isError = false;
      this.message = null;
      this.email = "";
      this.$emit("close");
    },

    errorMessage() {
      this.isError = true;
      this.message = "Something went wrong. Make sure you entered the correct email and try again.";
    }
  },

  validations: {
    email: { email, required }
  }
};
</script>
