<template>
  <input
    ref="root"
    :value="value"
    placeholder="MM/DD/YYYY"
    :disabled="disabled"
    class="form-control form-control-lg"
    :required="required"
    @input="onInput"
  >
</template>

<script>
import Flatpickr from "flatpickr";

export default {
  props: {
    value: {
      default: null,
      required: true,
      validator(value) {
        return (
          value === null
          || value instanceof Date
          || typeof value === "string"
          || value instanceof String
          || value instanceof Array
          || typeof value === "number"
        );
      }
    },
    // https://chmln.github.io/flatpickr/options/
    config: {
      type: Object,
      default: () => ({
        wrap: false,
        defaultDate: null
      })
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /**
       * The flatpickr instance
       */
      fp: null
    };
  },
  watch: {
    config: {
      deep: true,
      handler(newConfig) {
        this.fp.set(newConfig);
      }
    },
    value(newValue) {
      if (newValue === this.$refs.root.value) return;
      if (this.fp) {
        this.fp.setDate(newValue, true);
      }
    }
  },
  mounted() {
    // Return early if flatpickr is already loaded
    if (this.fp) return;

    const baseConfig = {
      altInput: true,
      altFormat: "m/d/Y",
      dateFormat: "Y-m-d",
      disableMobile: true
    };

    // Init flatpickr
    this.fp = new Flatpickr(this.$el, { ...baseConfig, ...this.config });

    // Attach blur event
    this.fpInput().addEventListener("blur", this.onBlur);
  },
  /**
   * Free up memory
   */
  beforeDestroy() {
    /* istanbul ignore else */
    if (this.fp) {
      this.fpInput().removeEventListener("blur", this.onBlur);
      this.fp.destroy();
      this.fp = null;
    }
  },
  methods: {
    /**
     * Watch for value changed by date-picker itself and notify parent component
     *
     * @param event
     */
    onInput(event) {
      const input = event.target;
      // Lets wait for DOM to be updated
      this.$nextTick(() => {
        this.$emit("input", input.value);
      });
    },

    /**
     * @return HTMLElement
     */
    fpInput() {
      return this.fp.altInput || this.fp.input;
    },

    /**
     * Blur event is required by many validation libraries
     *
     * @param event
     */
    onBlur(event) {
      this.$emit("blur", event.target.value);
    }
  }
};
</script>

<style></style>
