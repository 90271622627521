<template>
  <footer
    id="footer"
    class="footer"
  >
    <div
      v-if="$slots['footer-top']"
      class="footer-holder footer-top"
    >
      <slot name="footer-top" />
    </div>

    <div class="footer-holder">
      <div class="container-fluid">
        <div class="block-contact">
          <div class="logo logo-desktop">
            <a :href="url">
              <img
                :src="$settings_asset(settings.asset_logo_footer_desktop)"
                alt="Logo"
              >
            </a>
          </div>

          <div class="logo logo-mobile">
            <a :href="url">
              <img
                :src="$settings_asset(settings.asset_logo_footer_mobile)"
                alt="Logo"
              >
            </a>
          </div>

          <slot />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "CommonFooter",
  data() {
    return {
      url: `${process.env.VUE_APP_CUSTOMER_PORTAL_URL}active-loan/dashboard`
    };
  },
  computed: {
    ...mapGetters(["isReturnOfferLineOfCredit"]),
    ...mapState("customer", ["settings"]),
  },
  mounted() {
    if (this.isReturnOfferLineOfCredit && this.settings.company_chat_bot_loc) {
      const scriptTag = document.createElement("script");
      scriptTag.src = this.settings.company_chat_bot_loc;
      scriptTag.id = "chatbot-cdn";
      document.getElementsByTagName("head")[0].appendChild(scriptTag);
    }
  },
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  color: $footer-text-color;
  background: $footer-bg-color;
  overflow: hidden;

.footer-top {
  color: $footer-top-text-color;
  background: $footer-top-bg-color;
}
}

.footer .text {
  font-size: 12px;
  padding: 25px 0 10px;
}

.footer .text h6 {
  margin: 0;
  font-size: 12px;
}

.footer a {
  color: #fff !important;
}

.footer-info-mobile {
  display: none;
}

.nav-profile {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
& > li {
    padding: 5px 0;
    color: #e6e7e8;
&:hover {
   color: #fff;
   text-decoration: underline;
 }
}
}

.questions-block h3 {
  font-size: 24px;
}

.copyright {
  display: block;
  font-size: 12px;
}

.block-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.block-contact address {
  border-left: 1px solid #fff;
  padding-left: 22px;
  font-size: 16px;
  line-height: 1.4;
  margin: 0 0 20px 20px;
  font-style: normal;
}

.block-contact address span {
  display: block;
  margin-bottom: 3px;
}

.block-contact address span:last-child {
  margin-bottom: 0;
}

.block-contact address a {
  color: #fff;
}
.footer .footer-holder {
  padding: 25px 0;
  overflow: hidden;
  border-bottom: 1px solid $footer-holder-b-bottom-color;
}

.footer .footer-holder:last-child {
  border: none;
}

@media (max-width: 666px) {
  .block-contact {
    display: block;
    text-align: center;
  }

  .block-contact .logo {
    margin: 0 auto 30px;
  }

  .block-contact address {
    padding: 0;
    margin: 0;
    border-left: none;
  }

  .footer-info-mobile {
    display: block;
  }
}
</style>
