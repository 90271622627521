var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn",class:{
    'btn-full': _vm.buttonFull,
    'btn-min-width': _vm.buttonMinWidth,
    'btn-lg': _vm.size === 'large',
    'btn-sm': _vm.size === 'small',
    'btn-inverse': _vm.color === 'inverse',
    'btn-warning': _vm.color === 'warning',
    'btn-settlement': _vm.color === 'settlement',
    'btn-settlement-inverse': _vm.color === 'settlement-inverse',
    'btn-highlight': _vm.highlight
  },attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }