<template>
  <div>
    <CommonFooter v-if="!isOrrickBrand">
      <template #footer-top>
        <div class="container-fluid p-xs">
          <div class="text">
            <h6>IMPORTANT DISCLOSURES</h6>

            <FooterLinks v-if="showFooterLinks" />

            <p
              v-for="(item, index) in settings.disclosures"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </div>
      </template>

      <address>
        <span>{{ settings.company_address1 }}</span>
        <span>{{ settings.company_address2 }}</span>
        <span>
          <a :href="`tel:${settings.company_phone}`">{{ settings.company_phone }}</a>
        </span>
        <span>
          <a :href="`mailto:${settings.company_email}`">{{ settings.company_email }}</a>
        </span>
      </address>
    </CommonFooter>
    <OrrickFooter v-else />
  </div>
</template>

<script>

import CommonFooter from "@secure/layout/Footer/CommontFooter.vue";
import { mapGetters, mapState } from "vuex";
import OrrickFooter from "@secure/layout/Footer/OrrickFooter.vue";
import FooterLinks from "@secure/components/FooterLinks.vue";

export default {
  components: { FooterLinks, OrrickFooter, CommonFooter },

  computed: {
    ...mapGetters("customer", ["isOrrickBrand"]),
    ...mapState("customer", ["settings"]),
    showFooterLinks() {
      return this.settings?.show_portal_footer_links || false;
    }
  }
};
</script>

<style lang="scss"></style>
