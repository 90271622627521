import "./environment-banner.css";

function EnvironmentBanner(Vue) {
  Vue.component("EnvironmentBanner", {
    computed: {
      env() {
        return process.env.NODE_ENV;
      },
    },
    render() {
      if (this.env === "production") return;
      return (
        <span class="environment-banner">
          <span>{this.env}</span>
        </span>
      );
    },
  });
}

export default EnvironmentBanner;
