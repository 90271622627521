<template>
  <vue-recaptcha
    :sitekey="recaptchaSiteKey"
    :load-recaptcha-script="true"
    @verify="onVerify"
    @expired="onExpired"
    @error="onError"
  />
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import Bugsnag from "@bugsnag/js";

export default {
  name: "Recaptcha",
  components: { VueRecaptcha },
  data() {
    return {
      recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    };
  },
  methods: {
    onVerify(event) {
      this.$emit("verifyRecaptcha", event);
    },
    onExpired() {
      this.$emit("recaptchaExpired");
    },
    onError() {
      Bugsnag.notify("Recaptcha failed to load");
    },
  }
};
</script>

<style scoped>

</style>
