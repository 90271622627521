<template>
  <div>
    <div
      v-if="settings.show_anouncemnt_banner"
      id="fixed_announcement_banner"
    >
      {{ settings.anouncemnt_banner_text }}
    </div>
    <NoSettings v-if="!settingsReady && !loading" />
    <transition v-else-if="!loading">
      <transition name="slide-fade">
        <router-view />
      </transition>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import NoSettings from "./pages/NoSettings.vue";

export default {
  name: "App",
  components: {
    NoSettings,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState("customer", ["settings"]),
    ...mapGetters("customer", ["settingsReady"]),
  },
  async created() {
    this.loading = true;
    await this.getCustomerSettings();
    await this.getBrandSettings();
    this.loading = false;
  },
  methods: {
    ...mapActions("customer", ["getCustomerSettings", "getBrandSettings"]),
  }
};
</script>
<style lang="scss">
#wrapper {
  width: 100%;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-flow: column;

  #header {
    position: relative;
    border-bottom: $header_border_bottom_line;
  }

  #main {
    width: 100%;
    z-index: 1;
    overflow: auto;
    min-height: 450px;
    position: relative;
    overflow-x: hidden;
  }

  #footer {
    flex-grow: 1;
    position: relative;

    .logo {
      img {
        width: $footer-logo-desktop-width;
        height: $footer-logo-desktop-height;
      }
    }
  }
}

#fixed_announcement_banner {
  width: 100%;
  height: auto;
  background: $announcement_banner_background;
  color: $announcement_banner_text_color;
  text-align: center;
  border: 0px;
  padding: 10px;
  margin: 0px;
  font-weight: 600;
  font-size: 1em;

  @media (max-width: 1023px) {
    font-size: 0.85em;
  }
}
.content-background {
  background: $content-background;
}

.logo {
  position: relative;
  z-index: 10;
  margin: 0 20px 0 25px;

  &.logo-desktop {
    display: block;
  }

  &.logo-mobile {
    display: none;
  }

  img {
    width: $logo-desktop-width;
    height: $logo-desktop-height;
  }

  @media (max-width: 1023px) {
    &.logo-desktop {
      display: none;
    }

    &.logo-mobile {
      display: block;
    }
  }

  @media (max-width: 660px) {
    & img {
      width: $logo-img-width-on-620px;
      height: $logo-img-height-on-620px;
    }
  }

  @media (max-width: 359px) {
    & img {
      width: $logo-img-width-on-359px;
      height: $logo-img-height-on-359px;
    }
  }
}

a {
  color: $hyperlink-color !important;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  opacity: 0.5;
}
</style>
