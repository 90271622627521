<template>
  <div class="not-found">
    <h1 class="not-found__header">
      404
    </h1>
    <p class="not-found__text">
      We could not find that page.
    </p>
    <BaseButton @click="goHome">
      Return Home
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    goHome() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.not-found {
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  background-color: $fullloader-bg-color;
  &__header {
    font-size: 100px;
    font-weight: bold;
  }
  &__text {
    font-size: 30px;
  }
}

</style>
