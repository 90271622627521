<template>
  <div class="radio-hold">
    <label
      class="radio"
      :for="radioId"
    >
      <slot />
      <input
        :id="radioId"
        :checked="isChecked"
        :value="radioValue"
        type="radio"
        :required="required"
        :disabled="disabled"
        @change="change"
      >
      <span />
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioInput",
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    radioValue: {
      type: [String, Number, Boolean],
      default: null
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radioId: Math.random() * Date.now()
    };
  },
  computed: {
    isChecked() {
      return this.modelValue === this.radioValue;
    }
  },
  methods: {
    change() {
      this.$emit("input", this.radioValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.radio {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  padding-left: 35px;
  user-select: none;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  & span {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
    transition: background-color 0.2s;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  &:hover input ~ span {
    background-color: #ccc;
  }
  & input:checked ~ span {
    background-color: $radio-input-color;
  }
  & input:checked ~ span:after {
    display: block;
  }
  & span:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
  }
}
</style>
