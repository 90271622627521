<template>
  <div>
    <Popup
      :show="openModal"
      @close="close"
    >
      <template #header>
        <div class="mb-xs">
          <h1>Error Message</h1>
        </div>
      </template>

      <div>
        {{ message }}
      </div>
    </Popup>
  </div>
</template>

<script>
export default {
  name: "TemporarilyBlockedModal",

  props: {
    message: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      openModal: false,
    };
  },

  watch: {
    message: {
      deep: true,
      handler(value) {
        this.openModal = !!value;
      }
    },
  },
  methods: {
    close() {
      this.openModal = false;
      this.$emit("resetBlockMessage");
    },
  }
};
</script>
