// The mutation and action types that are available for this module
// for adding a new one use: export const TEST = "TEST";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_NOT_FOUND = "SET_NOT_FOUND";
export const SET_MAINTENANCE = "SET_MAINTENANCE";

export const loanMutations = {
  SET_PAYMENT_SCHEDULES: "SET_PAYMENT_SCHEDULES",
  SET_LOAN_CONFIG: "SET_LOAN_CONFIG",
  SET_CURRENT_STATUS: "SET_CURRENT_STATUS",
  SET_PAYMENTS: "SET_PAYMENTS",
  SET_AUTOPAYS: "SET_AUTOPAYS",
  SET_TRANSACTIONS: "SET_TRANSACTIONS",
  SET_BUSINESS_DAYS: "SET_BUSINESS_DAYS",
  SET_DEBT_COMPANY: "SET_DEBT_COMPANY",
  SET_LATEST_STATEMENT: "SET_LATEST_STATEMENT",
  SET_LATEST_PDS_SETTLEMENT: "SET_LATEST_PDS_SETTLEMENT",
};

export const customerMutations = {
  SET_SMS_APPROVED: "SET_SMS_APPROVED",
  SET_BASEINFO: "SET_BASEINFO",
  SET_DOCUMENTS: "SET_DOCUMENTS",
  SET_PHONES: "SET_PHONES",
  SET_PAYMENT_ACCOUNTS: "SET_PAYMENT_ACCOUNTS",
  SET_PRIMARY_ADDRESS: "SET_PRIMARY_ADDRESS",
  SET_LEAD_INFO: "SET_LEAD_INFO",
  SET_CUSTOMER_SETTINGS: "SET_CUSTOMER_SETTINGS",
  SET_BRAND_SETTINGS: "SET_BRAND_SETTINGS",
};
