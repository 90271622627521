import _pick from "lodash/pick";
import Vue from "vue";
import LpCustomer from "@secure/services/LpCustomerService";
import { customerMutations } from "../mutationTypes";

const initialState = {
  primaryAddress: {},
  paymentAccounts: [],
  documents: [],
  phones: [],
  baseInfo: {},
  leadInfo: {},
  isSmsApproved: false,
  settings: {},
  brandSettings: {},
};

const getters = {
  primaryAccount: state => state.paymentAccounts.find(account => account.isPrimary) || {},
  primaryPhone: state => state.phones.find(phone => phone.isPrimary) || {},
  fullName: state => `${state.baseInfo.firstName} ${state.baseInfo.lastName}`,
  customerId: state => state.baseInfo.customerId,
  settingsReady: state => Object.keys(state.settings).length,
  productDiscontinued: state => state.settings.product_discontinued,
  isOrrickBrand: state => state.settings.company_name.search("Orrick") !== -1
    || state.settings.company_name.search("orrick") !== -1
};

const actions = {
  changeSmsConsent({ commit, state }, value) {
    const payload = {
      leadGuid: state.leadInfo.lead_guid,
      smsConsent: value
    };

    return Vue.prototype.$axios.post("api/v1/tcpa/sms-consent", payload)
      .then(response => {
        if (response.status === 200) {
          commit(customerMutations.SET_SMS_APPROVED, value);
        }
        return response;
      });
  },

  init({ commit }, customer) {
    commit(customerMutations.SET_BASEINFO, customer);
    commit(customerMutations.SET_DOCUMENTS, customer.Documents);
    commit(customerMutations.SET_PHONES, customer.Phones);
    commit(customerMutations.SET_PAYMENT_ACCOUNTS, customer.PaymentAccounts);
    commit(customerMutations.SET_PRIMARY_ADDRESS, customer.PrimaryAddress);
  },

  sendVerificationCode({ state }, verificationMethod) {
    const payload = {
      customerGuid: state.leadInfo.guid,
      method: verificationMethod
    };

    return Vue.prototype.$axios.post("api/v1/customer/send-verification-email", payload);
  },

  // eslint-disable-next-line no-unused-vars
  storeDirectMailLogin({ state }, payload) {
    return Vue.prototype.$axios.post("api/v1/customer/direct-mail-login", payload);
  },

  async checkVerificationCode({ state }, code) {
    const payload = {
      code,
      customerGuid: state.leadInfo.guid
    };

    let result = { verified: false, message: null };

    try {
      const resp = await Vue.prototype.$axios.post("api/v1/customer/check-verification-code", payload);

      // if response has verified property, then it's a valid response
      if (resp.data.verified !== undefined) {
        result = resp.data;
      }
    } catch (error) {
      // if response has status property, then it's a valid response
      if (error.response) {
        result = { ...result, message: error.response.data.message };
      }
    }

    return result;
  },

  fetchCustomer({ dispatch }) {
    return LpCustomer.getCustomer().then(response => {
      if (response.status === 200) {
        dispatch("init", response.data.d);
      }
    });
  },

  updatePassword({ dispatch }, password) {
    return LpCustomer.updatePassword(password).then(async response => {
      if (response.status === 200) {
        await dispatch("fetchCustomer");
        return true;
      }
      return false;
    });
  },

  updatePrimaryPaymentAccount({ dispatch }, accountId) {
    return LpCustomer.updatePrimaryPaymentAccount(accountId).then(async response => {
      if (response.status === 200) {
        await dispatch("fetchCustomer");
        return true;
      }
      return false;
    });
  },

  addBankAccount({ dispatch }, { token, bankAccountType, title }) {
    return LpCustomer.addBankAccount(token, bankAccountType, title).then(async response => {
      if (response.status === 200) {
        await dispatch("fetchCustomer");
        return true;
      }
      return false;
    });
  },

  addDebitCard({ dispatch }, { token, title }) {
    return LpCustomer.addDebitCard(token, title).then(async response => {
      if (response.status === 200) {
        await dispatch("fetchCustomer");
        return true;
      }
      return false;
    });
  },

  setLeadInfo({ commit, dispatch }, payload) {
    commit(customerMutations.SET_SMS_APPROVED, payload.sms_consent || false);

    dispatch("loan/setBusinessDays", payload.business_days, { root: true });
    Vue.delete(payload, "business_days");

    commit(customerMutations.SET_LEAD_INFO, payload);
  },

  async getCustomerSettings({ commit }) {
    const settings = await Vue.prototype.$axios.get("api/v1/customer/settings/CustomerPortal");

    commit(customerMutations.SET_CUSTOMER_SETTINGS, settings.data);
  },

  async getBrandSettings({ commit }) {
    const settings = await Vue.prototype.$axios.get("api/v1/customer/settings/Brand");

    commit(customerMutations.SET_BRAND_SETTINGS, settings.data);
  }
};

const mutations = {
  [customerMutations.SET_SMS_APPROVED]: (state, value) => {
    state.isSmsApproved = value;
  },

  [customerMutations.SET_BASEINFO]: (state, payload) => {
    const customerInfo = _pick(payload, ["id", "customId", "firstName", "lastName", "email"]);

    customerInfo.customerId = customerInfo.id;
    delete customerInfo.id;

    state.baseInfo = customerInfo;
  },

  [customerMutations.SET_CUSTOMER_SETTINGS]: (state, settings) => {
    state.settings = settings;
  },

  [customerMutations.SET_BRAND_SETTINGS]: (state, settings) => {
    state.brandSettings = settings;
  },

  [customerMutations.SET_DOCUMENTS]: (state, documents) => {
    const parsedData = documents.results
      .filter((doc) => Number(doc.archived) === 0)
      .map((doc) => {
        const {
          parentId: documentId, fileName, fileOriginalName, fileMime
        } = doc.FileAttachment;
        return {
          documentId,
          fileName,
          fileOriginalName,
          fileMime,
        };
      });

    state.documents = parsedData;
  },

  [customerMutations.SET_PHONES]: (state, phones) => {
    state.phones = phones.results;
  },

  [customerMutations.SET_PAYMENT_ACCOUNTS]: (state, paymentAccounts) => {
    state.paymentAccounts = paymentAccounts.results;
  },

  [customerMutations.SET_PRIMARY_ADDRESS]: (state, primaryAddress) => {
    state.primaryAddress = primaryAddress;
  },

  [customerMutations.SET_LEAD_INFO]: (state, leadInfo) => {
    state.leadInfo = leadInfo;
  }
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
