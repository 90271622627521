import {
  AUTH_LOGIN, AUTH_LOGOUT, SET_NOT_FOUND, SET_MAINTENANCE
} from "../mutationTypes";

const initialState = {
  isLoggedIn: false,
  notFound: false,
  maintenance: false,
  version: process.env.VUE_APP_VERSION,
};

const getters = {};

const actions = {
  async handleLoginSuccess({ commit }, $axios) {
    commit(AUTH_LOGIN);
    await $axios.post("api/v1/customer-login-metric/track-successful-login");
  }
};

const mutations = {
  [AUTH_LOGIN]: state => {
    state.isLoggedIn = true;
  },
  [AUTH_LOGOUT]: state => {
    state.isLoggedIn = false;
  },
  [SET_NOT_FOUND]: (state, value) => {
    state.notFound = value;
  },
  [SET_MAINTENANCE]: (state, value) => {
    state.maintenance = value;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
};
