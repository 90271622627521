<template>
  <div>
    <h2
      v-if="title"
      class="title"
    >
      {{ title }}
    </h2>
    <slot />
  </div>
</template>

<script>
export default {
  name: "Accordion",
  provide() {
    return { Accordion: this.Accordion };
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    defaultActiveIndex: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      Accordion: {
        count: 0,
        activeIndex: this.defaultActiveIndex
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.5em;
  font-weight: bold;
  padding: 10px 5%;
}

@media (max-width: 380px) {
  .title {
    font-size: 1.4em;
  }
}
</style>
