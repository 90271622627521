import Vue from "vue";
import { DateTime } from "luxon-business-days";
import lodashGet from "lodash/get";
import lodashFind from "lodash/find";
import { PaymentMethods, PaymentAccountTypes, AutopayCancelReturnCodes } from "./LpConstants";

export default {
  getStatement(loanId, statementId) {
    return Vue.prototype.$axios
      .get(`api/v1/loan/${loanId}/statements/${statementId}`, { responseType: "arraybuffer" })
      .catch(this.errorHandler);
  },

  async getLatestStatement(loanId) {
    const response = await Vue.prototype.$axios
      .get(`api/v1/loan/${loanId}/latest-statement`).catch(this.errorHandler);

    if (response.status === 200) {
      return response.data;
    }

    return false;
  },

  async getLatestPdsSettlement(loanId) {
    const response = await Vue.prototype.$axios
      .get(`api/v1/loan/${loanId}/pds-settlement`).catch(this.errorHandler);

    if (response.status === 200) {
      return response.data;
    }

    return false;
  },

  async downloadSettlementPaymentSchedule(loanId, reducedPayoffPlanId) {
    const response = await Vue.prototype.$axios
      .get(
        `api/v1/loan/${loanId}/pds-settlement/reduced-payoff-plan/${reducedPayoffPlanId}/download-payment-schedule`,
        { responseType: "blob" }
      ).catch(this.errorHandler);

    if (response.status === 200) {
      const date = DateTime.now().toUTC().toFormat("yyyy-MM-dd");
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `settlement-payment-schedule-${loanId}-${reducedPayoffPlanId}-${date}.pdf`;
      link.click();
      link.remove();
      return response.data;
    }

    return false;
  },

  async getLoanData(loanId) {
    const response = await Vue.prototype.$axios.get(`api/v1/loan/${loanId}`).catch(this.errorHandler);

    if (response.status === 200) {
      return lodashGet(response.data, "d");
    }

    return false;
  },

  async getOrigPaymentSchedules(loanId) {
    const response = await Vue.prototype.$axios.post("api/v1/loan/org-payment-schedule", { loanId });

    if (response.status === 200) {
      return response.data;
    }

    return false;
  },

  async getPayments(loanId) {
    // eslint-disable-next-line max-len
    const isAutopayCancelled = (returnCode) => AutopayCancelReturnCodes.some((code) => returnCode && returnCode.includes(code));

    const getPaymentAccountType = (paymentMethodId) => {
      let accountType = "";

      if (paymentMethodId === PaymentMethods.CREDIT) {
        accountType = "Card";
      } else if (paymentMethodId === PaymentMethods.CHECKING) {
        accountType = "Bank Account";
      }

      return accountType;
    };

    const getPaymentAccountTypeLastFour = (paymentMethodOption) => {
      let paymentAccountLastFour = "";

      if (paymentMethodOption) {
        const accountNumber = paymentMethodOption.split("-")[0];
        paymentAccountLastFour = accountNumber.substr(accountNumber.length - 4).replace(/\D/g, "");
      }

      return paymentAccountLastFour;
    };

    const response = await Vue.prototype.$axios.get(`api/v1/loan/${loanId}/payments`).catch(this.errorHandler);

    if (response.status === 200) {
      return lodashGet(response.data, "d.results").map((payment) => ({
        title: `Payment: ${payment.PaymentType.title} - ${payment.info}`,
        date: this.parseApiDate(payment.date),
        paymentAmount: payment.amount,
        nachaReturnCode: payment.nachaReturnCode,
        autopayCancelled: payment.autopayId && isAutopayCancelled(payment.nachaReturnCode),
        paymentAccountType: getPaymentAccountType(payment.paymentMethodId),
        paymentAccountLastFour: getPaymentAccountTypeLastFour(payment.paymentMethodOption),
      }));
    }

    return false;
  },

  async getTransactions(loanId) {
    const response = await Vue.prototype.$axios.get(`api/v1/loan/${loanId}/transactions`).catch(this.errorHandler);

    if (response.status === 200) {
      return lodashGet(response.data, "d.results").map((t) => {
        const loanTx = t;
        loanTx.date = this.parseApiDate(loanTx.date);
        return loanTx;
      });
    }

    return false;
  },

  async getAutopays(loanId) {
    const response = await Vue.prototype.$axios.get(`api/v1/loan/${loanId}/autopays`).catch(this.errorHandler);

    if (response.status === 200) {
      return lodashGet(response.data, "d.results").map((value) => {
        const autopay = value;

        // currently we are removing time aspect from processDateTime in the below parseApiDate() function
        // we now need the time too, but I don't want to alter autopay.processDateTime since the rest of code
        // base is expecting just a date. So solution is this new variable, processDateTimeFromLp
        autopay.processDateTimeFromLp = autopay.processDateTime;
        autopay.applyDate = this.parseApiDate(autopay.applyDate);
        autopay.processDateTime = this.parseApiDate(autopay.processDateTime);

        return autopay;
      });
    }

    return false;
  },

  async getCurrentStatus(loanId) {
    const response = await Vue.prototype.$axios.get(`api/v1/loan/${loanId}/current-status`).catch(this.errorHandler);

    if (response.status === 200) {
      return response.data;
    }

    return false;
  },

  async getAdvances(loanId) {
    const response = await Vue.prototype.$axios
      .get(`api/v1/line-of-credit/${loanId}/advances`)
      .catch(this.errorHandler);

    if (response.status === 200) {
      return response.data;
    }

    return [];
  },

  async getLoanPayoff(loanId, date) {
    const response = await Vue.prototype.$axios
      .get(`api/v1/loan/${loanId}/payoff-amount?date=${date}`)
      .catch(this.errorHandler);

    if (response.status === 200) {
      return lodashGet(response.data, `d[${date}].payoff`);
    }

    return false;
  },

  async makePaymentNow(loanId, paymentData) {
    const { paymentAmount, paymentDate, paymentAccountId } = paymentData;

    const response = await Vue.prototype.$axios
      .post(`api/v1/loan/${loanId}/payments/${paymentAccountId}`, {
        paymentAmount,
        paymentDate,
      })
      .catch(this.errorHandler);

    return response.status === 200 && response.data === true;
  },

  async schedulePayment(loanId, options) {
    // MAKE LOANPRO CALL
    const response = await Vue.prototype.$axios
      .post(`api/v1/loan/${loanId}/autopays/${options.paymentAccountId}/single`, options)
      .catch(this.errorHandler);

    return response.status === 200 && response.data === true;
  },

  async createSettlement(options) {
    // MAKE LOANPRO CALL
    const response = await Vue.prototype.$axios
      .post(`api/v1/loan/${options.loanId}/autopays/${options.paymentAccountId}/settlement`, options)
      .catch(this.errorHandler);

    if (response.status !== 200) {
      throw response;
    }

    return response.data === true;
  },

  /**
   * Create Manual Settlement.
   * @param options
   * @returns {Promise<boolean>}
   */
  async createManualSettlement(options) {
    const response = await Vue.prototype.$axios
      .post(`api/v1/loan/${options.loanId}/manual/settlement`, options)
      .catch(this.errorHandler);

    if (response.status !== 200) {
      throw response;
    }

    return response.data === true;
  },

  async createLoanRecurringAutopay(loanId, paymentAccountId) {
    const response = await Vue.prototype.$axios
      .post(`api/v1/loan/${loanId}/autopays/${paymentAccountId}/schedule`)
      .catch(this.errorHandler);

    return response.status === 200 && response.data === true;
  },

  async updateAutopayPayment(selectedAccount, autopays) {
    if (autopays.length === 0) return;

    const [{ loanId }] = autopays; // pick loanId from the first row

    const options = {
      selectedAccount,
      autopays,
    };

    const response = await Vue.prototype.$axios
      .post(`api/v1/loan/${loanId}/autopays/${selectedAccount.id}/update`, options)
      .catch(this.errorHandler);

    return response.status === 200 && response.data === true;
  },

  async deleteAutopay(loanId, autopayId) {
    return Vue.prototype.$axios.delete(`api/v1/loan/${loanId}/autopays/${autopayId}`).catch(this.errorHandler);
  },

  parseApiDate(dateStr) {
    // look for a match like this: /Date(1595116800)/  and capture timestamps
    const regex = /^\/Date\((-?[0-9]+)\)\/$/;

    if (regex.test(dateStr)) {
      const timestamps = Number(regex.exec(dateStr)[1]);

      return DateTime.fromSeconds(timestamps, { zone: "UTC" }).toFormat("yyyy-MM-dd");
    }

    return "";
  },

  isBankAccount(paymentAccounts, paymentAccountId) {
    return Boolean(lodashFind(paymentAccounts, { id: paymentAccountId, type: PaymentAccountTypes.CHECKING }));
  },

  async getDebtCompany(loanId) {
    const response = await Vue.prototype.$axios
      .get(`api/v1/loan/${loanId}/debt-company`)
      .catch(this.errorHandler);

    if (response.status === 200) {
      return response.data;
    }

    return false;
  },

  errorHandler(error) {
    if (error.response) {
      return error.response.data;
    }
    if (error.request) {
      return error.request;
    }
    return error.message;
  },
};
