<template>
  <input
    type="text"
    :value="value"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    :maxlength="maxLength"
    @change="$emit('input', $event.target.value)"
    @blur="$emit('blur', $event.target.value)"
    @focus="$emit('focus', $event.target.value)"
  >
</template>

<script>
export default {
  props: {
    mask: {
      type: String,
      default: ""
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: [String, Number],
      default: ""
    }
  }
};
</script>
